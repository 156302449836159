import React, { useEffect } from 'react';
import i18n from 'i18next';
import ConversationVoting from './utils';
import ConversationVote from './ConversationVote';
import { useCookies } from 'react-cookie';
import { Params, useParams, useSearchParams } from 'react-router-dom';
import { useSetConversationUserId } from '../../../network';

interface InternalConversationVoteRouteParams extends Params {
  conversationId: string;
}

export default function InternalConversationVote(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies(['userOrigin', 'conversationUserId']);
  const { conversationId } = useParams() as InternalConversationVoteRouteParams;

  useEffect(() => {
    // Hotfix for Adient discussions, as they need as EN but by default the LanguageDetector set SK for them.
    // If Adient discussion, change language to EN.
    if (conversationId == '14') {
      i18n.changeLanguage('en');
    }
  }, []);
  const referral = searchParams.get('utm_source');

  const conversationUserId = cookies.conversationUserId;
  const userOrigin = cookies.userOrigin;
  const facebookUserId = null;

  let userId: string | null = null;
  if (userOrigin && facebookUserId) {
    userId = `${facebookUserId}_${userOrigin}`;
  } else if (userOrigin && conversationUserId) {
    userId = `${conversationUserId}_${userOrigin}`;
  }

  const { isSuccess } = useSetConversationUserId(conversationId, userId, referral, {
    enabled: !userId,
  });

  if (!userId) {
    if (isSuccess) {
      console.error('Nespravne nastavena konverzacia (ziadne user ID)');
      return (
        <h1 style={{ textAlign: 'center' }}>Nespravne nastavena konverzacia (ziadne user ID)</h1>
      );
    } else {
      return <></>;
    }
  }

  return (
    <ConversationVoting
      conversation={(conversationId: string) => (
        <ConversationVote conversationId={conversationId} conversationUserId={userId ?? ''} />
      )}
    />
  );
}
