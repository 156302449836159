import React from 'react';
import { Box, Button, Center, Flex, PasswordInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import useAuth from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

function LoginPage(): JSX.Element {
  const { t } = useTranslation('login');
  const { login } = useAuth();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : t('invalidMail')),
    },
  });

  return (
    <Center h={'100vh'}>
      <Box w={400}>
        <form
          onSubmit={form.onSubmit((values) => {
            login(values.email, values.password);
          })}
        >
          <TextInput label='Email' placeholder='Enter your mail' {...form.getInputProps('email')} />
          <PasswordInput
            label='Password'
            placeholder='Enter your password'
            {...form.getInputProps('password')}
          />
          <Flex w={'100%'} justify={'end'}>
            <Button mt={20} type='submit'>
              {t('loginButton')}
            </Button>
          </Flex>
        </form>
      </Box>
    </Center>
  );
}

export default LoginPage;
