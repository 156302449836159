export type ConversationType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  isActive: boolean;
  createdAt: string;
};

export type ConversationDetailType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  iframePolisUrlPath: string;
  updatedAt: Date;
  createdAt: Date;
  isActive: boolean;
  isPublic: boolean;
};

export type InternalReportSourceStats = {
  name: string;
  votes: number;
  comments: number;
  participants: number;
  clusteredParticipants: number;
  clusters: { clusterName: string; participants: number }[];
};

export type ConversationStatisticsType = {
  voteCount: number;
  participantsGrouped: number;
  participantsCount: number;
  commentsCount: number;
};

export type PointSchema = {
  x: number;
  y: number;
};

export type ClusteredConversationPoints = {
  name: string;
  clusterCenter: PointSchema;
  points: PointSchema[];
};

export type ConsensusCommentGroup = {
  name: string;
  percentage: number;
};

export type ConsensusComment = {
  text: string;
  meanAgreementPercentage: number;
  groups: ConsensusCommentGroup[];
};

export type GroupDefiningCommentType = {
  text: string;
  agreementPercentage: number;
  groups: ConsensusCommentGroup[];
};

export type GroupDefiningCommentGroup = {
  name: string;
  comments: GroupDefiningCommentType[];
};

export type CommentInClusterType = {
  clusterName: string;
  agreementPercentage: number;
  agreeCount: number;
  disagreeCount: number;
  skippedCount: number;
  unseenCount: number;
};

export type ConsensusCommentReportType = {
  id: number;
  text: string;
  polarizationMeasurement: number;
  meanAgreementPercentage: number;
  consensusPoints: number;
  groups: CommentInClusterType[];
};

export type ConversationVoteType = {
  vote: string;
  conversationId: number;
  commentId: number;
  participantId: number;
  createdAt: string;
};

export type ConversationParticipantType = {
  id: number;
  conversationId: number;
  userId: number;
  createdAt: string;
};

export type ConversationReportClusterDataComments = {
  [name: string]: ConsensusCommentReportType[];
};
export type ConversationReportClusterDataType = {
  clusterPoints: ClusteredConversationPoints[];
  comments: ConversationReportClusterDataComments;
  votes: ConversationVoteType[];
  participants: ConversationParticipantType[];
};

export enum ConversationSourceTypeEnum {
  INTERNAL_SOURCE = 'internal_source',
  EXTERNAL_SOURCE = 'external_source',
}
