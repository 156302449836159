import React, { memo, useMemo, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import {
  useConversationDetail,
  useConversationVotes,
  useDeleteConversation,
  useExternalConversationEmbedCode,
  useInternalConversationEmbedCode,
} from '../../../network';
import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  Button,
  Code,
  Container,
  CopyButton,
  Flex,
  Group,
  Modal,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import GraphOfVotesOverTime from '../../../components/dataVisualized/charts/GraphOfVotesOverTime';
import { IconExternalLink, IconReportSearch, IconSlideshow } from '@tabler/icons-react';

interface ConversationDetailRouterProps extends Params {
  conversationId: string;
}

export function ConversationDetail(): JSX.Element {
  const { t } = useTranslation('conversationDetail');
  const { conversationId } = useParams<ConversationDetailRouterProps>();

  if (conversationId === undefined) {
    return <div>Invalid conversation ID</div>;
  }

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);

  const { data } = useConversationDetail(conversationId);
  const { data: votes } = useConversationVotes(conversationId);

  const conversationPolisUrl = useMemo(() => {
    return `${process.env.REACT_APP_POLIS_URL}/m/${data?.iframePolisUrlPath}`;
  }, [data?.iframePolisUrlPath]);

  return (
    <div>
      <Header />
      <Container fluid={true}>
        {data && (
          <>
            <Flex align={'center'} wrap={'wrap'}>
              <Title order={1} mr={10}>
                {data.name}
              </Title>
              <Badge size={'lg'} radius={'sm'} color={data.isActive ? 'green' : 'red'}>
                {data.isActive ? t('open') : t('closed')}
              </Badge>
            </Flex>
            <DeleteConversationModal
              conversationId={conversationId}
              isDeleteModalOpened={isDeleteModalOpened}
              setIsDeleteModalOpened={setIsDeleteModalOpened}
            />
            <Group spacing={'xs'} mt={15}>
              <ExternalCodeButtonModal conversationId={conversationId} />
              <InternalCodeButton conversationId={conversationId} />
            </Group>
            <Group spacing={'xs'} mt={15}>
              <Button
                component={'a'}
                variant={'outline'}
                href={`/conversations/${conversationId}/vote/internal`}
              >
                {t('directVote')}
              </Button>
              <Button
                component={'a'}
                variant={'outline'}
                target='_blank'
                href={conversationPolisUrl}
                leftIcon={<IconExternalLink />}
              >
                Polis
              </Button>
              <Button
                component={'a'}
                variant={'outline'}
                leftIcon={<IconReportSearch />}
                href={`/conversations/${conversationId}/report/external`}
              >
                {t('externalReport')}
              </Button>

              <Button
                component={'a'}
                variant={'outline'}
                leftIcon={<IconReportSearch />}
                href={`/conversations/${conversationId}/report/internal`}
              >
                {t('internalReport')}
              </Button>
              <Button
                component={'a'}
                variant={'outline'}
                leftIcon={<IconSlideshow />}
                href={`/conversations/${conversationId}/presentation`}
              >
                {t('presentation')}
              </Button>

              <Button
                color={'red'}
                onClick={() => {
                  setIsDeleteModalOpened(true);
                }}
                variant={'outline'}
              >
                {t('delete')}
              </Button>
            </Group>
            <Title order={4} mt={15}>
              {t('description')}
            </Title>
            <Text>{data.description || 'No description'}</Text>
            <Title order={4} mt={15}>
              {t('polisConversationID')}
            </Title>
            <Text>{data.iframePolisUrlPath}</Text>
            {votes && (
              <Box py={30}>
                <GraphOfVotesOverTime votes={votes} />
              </Box>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

const InternalCodeButton = memo(function InternalCodeButton({
  conversationId,
}: {
  conversationId: string;
}): JSX.Element {
  const { data, isLoading } = useInternalConversationEmbedCode(conversationId);

  return (
    <CopyButton value={data || ''} timeout={2000}>
      {({ copied, copy }) => (
        <Button loading={isLoading} color={copied ? 'green' : 'blue'} onClick={() => copy()}>
          {copied ? 'Code copied' : 'Internal Iframe Code'}
        </Button>
      )}
    </CopyButton>
  );
});

function ExternalCodeButtonModal({ conversationId }: { conversationId: string }): JSX.Element {
  const { t } = useTranslation('conversationDetail');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [referralUrl, setReferralUrl] = useState('');

  const { data } = useExternalConversationEmbedCode(conversationId, referralUrl, {
    enabled: isModalOpened && referralUrl.length > 0,
  });

  const form = useForm({
    initialValues: {
      referralUrl: '',
    },

    validate: {
      referralUrl: (value) =>
        /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z0-9]{1,63}){1}$/.test(value)
          ? null
          : 'Nesprávny format URL pre partnera',
    },
  });

  return (
    <>
      <Button onClick={() => setIsModalOpened(true)}> {t('getIframe')} </Button>
      <Modal
        centered={true}
        withCloseButton={false}
        size={'xl'}
        opened={isModalOpened}
        onClose={() => {
          setIsModalOpened(false);
          setReferralUrl('');
          form.reset();
        }}
      >
        <form onSubmit={form.onSubmit((values) => setReferralUrl(values.referralUrl))}>
          <TextInput
            withAsterisk={true}
            label='Referral URL'
            placeholder='nm.sk, pravda.sk, sme.sk, ...'
            {...form.getInputProps('referralUrl')}
          />
          <Group position='right' mt='sm'>
            <Button type='submit'>Get embed code</Button>
          </Group>
        </form>
        {data && referralUrl && (
          <Flex direction={'column'} mt={'md'}>
            <CopyButton value={data}>
              {({ copied, copy }) => (
                <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                  {copied ? 'Code copied' : 'Copy code'}
                </Button>
              )}
            </CopyButton>
            <Code mt={15} block={true}>
              {data}
            </Code>
          </Flex>
        )}
      </Modal>
    </>
  );
}

function DeleteConversationModal({
  conversationId,
  isDeleteModalOpened,
  setIsDeleteModalOpened,
}: {
  conversationId: string;
  isDeleteModalOpened: boolean;
  setIsDeleteModalOpened: (state: boolean) => void;
}): JSX.Element {
  const { mutate: deleteConversation } = useDeleteConversation();

  const navigate = useNavigate();

  function handleDeleteButtonClick(): void {
    deleteConversation(conversationId, { onSuccess: () => navigate('/', { replace: true }) });
  }

  return (
    <Modal
      opened={isDeleteModalOpened}
      onClose={() => {
        setIsDeleteModalOpened(false);
      }}
      centered={true}
      overlayProps={{
        opacity: 0.55,
        blur: 3,
      }}
      title={'Are you sure you want to delete this conversation?'}
    >
      <Group spacing={'xs'} position={'center'} mt={20}>
        <Button onClick={handleDeleteButtonClick} color={'red'} variant={'outline'}>
          Delete
        </Button>
        <Button
          onClick={() => {
            setIsDeleteModalOpened(false);
          }}
          variant={'outline'}
        >
          Cancel
        </Button>
      </Group>
    </Modal>
  );
}
