import { CommentInClusterType, ConsensusCommentReportType } from '../components/types/Conversation';

export function getConsensusFromClusterComments(
  comment: ConsensusCommentReportType,
): CommentInClusterType {
  return {
    clusterName: '',
    agreementPercentage:
      comment.groups.reduce((acc, group) => acc + group.agreementPercentage, 0) /
      comment.groups.length,
    agreeCount: comment.groups.reduce((acc, group) => acc + group.agreeCount, 0),
    disagreeCount: comment.groups.reduce((acc, group) => acc + group.disagreeCount, 0),
    skippedCount: comment.groups.reduce((acc, group) => acc + group.skippedCount, 0),
    unseenCount: comment.groups.reduce((acc, group) => acc + group.unseenCount, 0),
  };
}
