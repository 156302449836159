import React, { useEffect } from 'react';
import { generatePath, matchPath, useNavigate } from 'react-router-dom';

interface RedirectWithParamsProps {
  redirects: { from: string; to: string }[];
}

const RedirectWithParams = ({ redirects }: RedirectWithParamsProps): JSX.Element => {
  const navigate = useNavigate();
  const path = window.location.pathname;
  let redirectFound = false;

  useEffect(() => {
    for (const red of redirects) {
      const { from, to } = red;
      const result = matchPath(from, path);
      if (result != null) {
        redirect(from, to, (newPath) => navigate(newPath, { replace: true }));
        redirectFound = true;
      }
    }

    if (!redirectFound) {
      navigate('/', { replace: true });
    }
  }, []);

  return <></>;
};

function redirect(from: string, to: string, navigate: (path: string) => void): void {
  const fromVariablesNames = extractVars(from);
  const toVariablesNames = extractVars(from);
  for (const variable of toVariablesNames) {
    if (!fromVariablesNames.includes(variable)) {
      console.error(`Variable ${variable} is not present in the to path`);
      throw new Error(`Variable ${variable} is not present in the to path`);
    }
  }

  const fromParts = from.split('/');
  const fromVariableIndexes = fromVariablesNames.map((variable) =>
    fromParts.indexOf(`:${variable}`),
  );

  const splitLocation = window.location.pathname.split('/');

  const fromVariables = Object.fromEntries(
    fromVariableIndexes.map((index, i) => [fromVariablesNames[i], splitLocation[index]]),
  );

  const generatedPath = generatePath(to, fromVariables);

  navigate(generatedPath);
}

function extractVars(url: string): string[] {
  const regex = /:(\w+)/g;
  let match;
  const arr = [];
  while ((match = regex.exec(url)) !== null) {
    arr.push(match[1]);
  }
  return arr;
}

export default RedirectWithParams;
