import React from 'react';
import { Params, useParams } from 'react-router-dom';
import {
  useConversationDetail,
  useConversationReportSourceStats,
  useConversationReportStatistics,
} from '../../../../network';
import { Container, Divider, Skeleton, Stack, Title } from '@mantine/core';
import Header from '../../../../components/Header';
import { ConversationSourceTypeEnum } from '../../../../components/types/Conversation';
import CustomConversationCluster from './CustomConversationCluster';
import ConversationStatistics from '../common';
import SourceStatsTable from './SourceStatsTable';

interface InternalConversationReportRouterProps extends Params {
  conversationId: string;
}

export function InternalConversationReport(): JSX.Element {
  const { conversationId } = useParams<InternalConversationReportRouterProps>();

  if (conversationId === undefined) {
    return <div>Invalid conversation ID</div>;
  }

  const { data: detail } = useConversationDetail(conversationId);
  const { data: statistics } = useConversationReportStatistics(conversationId);
  const { data: externalStats, isLoading: isExternalStatsLoading } =
    useConversationReportSourceStats(conversationId, ConversationSourceTypeEnum.EXTERNAL_SOURCE);
  const { data: internalSourceStats, isLoading: isInternalSourceStatsLoading } =
    useConversationReportSourceStats(conversationId, ConversationSourceTypeEnum.INTERNAL_SOURCE);

  return (
    <div>
      <Header />
      <Container fluid={true}>
        {detail ? <Title order={1}>{detail.name}</Title> : <Skeleton height={40} />}
        <ConversationStatistics statistics={statistics} conversationName={detail?.name || ''} />
        <Stack pt={20} spacing={'md'}>
          <SourceStatsTable
            label={'external'}
            statistics={statistics}
            sourceStats={externalStats}
            isSourceStatsLoading={isExternalStatsLoading}
          />
          <SourceStatsTable
            label={'internal'}
            statistics={statistics}
            sourceStats={internalSourceStats}
            isSourceStatsLoading={isInternalSourceStatsLoading}
          />
          <Divider />
          <CustomConversationCluster conversationId={conversationId} />
        </Stack>
      </Container>
    </div>
  );
}
