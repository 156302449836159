import React, { useRef } from 'react';

import * as htmlToImage from 'html-to-image';
import { ConversationStatisticsType } from '../../../components/types/Conversation';
import { useTranslation } from 'react-i18next';
import {
  IconScreenshot,
  IconUserFilled,
  IconUsersGroup,
  IconMessageCircle2Filled,
  IconHandStop,
} from '@tabler/icons-react';
import { Group, Flex, ActionIcon } from '@mantine/core';
import { Stat } from '../../../components/Stat';

export default function ConversationStatistics({
  statistics,
  conversationName,
}: {
  statistics: ConversationStatisticsType | undefined;
  conversationName: string;
}): JSX.Element {
  const groupRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('report');

  if (!statistics) {
    return <>Loading...</>;
  } else {
    return (
      <Flex mt={20}>
        <Group ref={groupRef} grow={false} spacing={10} noWrap={false}>
          <Stat
            value={statistics.participantsCount}
            label={t('participants')}
            icon={<IconUserFilled size={30} />}
          />
          <Stat
            value={statistics.participantsGrouped}
            label={t('groupedParticipants')}
            icon={<IconUsersGroup size={35} />}
          />
          <Stat
            value={statistics.commentsCount}
            label={t('statsComments')}
            icon={<IconMessageCircle2Filled size={35} />}
          />
          <Stat value={statistics.voteCount} label={t('votes')} icon={<IconHandStop size={35} />} />
        </Group>
        {groupRef && (
          <ActionIcon
            onClick={() => {
              const group = groupRef.current;
              if (group) {
                htmlToImage.toPng(group).then((dataUrl: string) => {
                  const link = document.createElement('a');
                  link.download = `${conversationName} - stats.png`;
                  link.href = dataUrl;
                  link.click();
                });
              }
            }}
          >
            <IconScreenshot />
          </ActionIcon>
        )}
      </Flex>
    );
  }
}
