import common from './common.json';
import menu from './menu.json';
import conversations from './conversations.json';
import stats from './stats.json';
import errors from './errors.json';
import report from './report.json';
import presentation from './presentation.json';
import login from './login.json';
import graphs from './graphs.json';
import authContext from './authContext.json';
import conversationDetail from './conversationDetail.json';
import consensusCommentDisplay from './consensusCommentDisplay.json';
import groupDefDisplay from './groupDefDisplay.json';
import vote from './vote.json';

export const en = {
  common,
  menu,
  conversations,
  stats,
  errors,
  report,
  presentation,
  login,
  graphs,
  authContext,
  conversationDetail,
  consensusCommentDisplay,
  groupDefDisplay,
  vote,
};
