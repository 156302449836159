import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Root } from './Root';
import { ConversationDetail } from './conversation/detail/ConversationDetail';
import { ExternalConversationReport } from './conversation/report/ExternalConversationReport';
import { ConversationPresentation } from './conversation/presentation/ConversationPresentation';
import useAuth, { AuthProvider } from '../contexts/AuthContext';
import LoginPage from './LoginPage';
import InternalConversationVote from './conversation/voting/InternalConversationVote';
import ExternalConversationVote from './conversation/voting/ExternalConversationVote';
import RedirectWithParams from '../utils/RedirectWithParams';
import { InternalConversationReport } from './conversation/report/internal/InternalConversationReport';

export const ProtectedRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to='/login' replace={true} state={{ path: location.pathname }} />;
  }

  return children;
};

export function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            path={'/'}
            element={
              <ProtectedRoute>
                <Root />
              </ProtectedRoute>
            }
          />
          <Route
            path={'/conversations/:conversationId'}
            element={
              <ProtectedRoute>
                <ConversationDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path={'/conversations/:conversationId/presentation'}
            element={
              <ProtectedRoute>
                <ConversationPresentation />
              </ProtectedRoute>
            }
          />
          <Route
            path={'/conversations/:conversationId/report/external'}
            element={<ExternalConversationReport />}
          />
          <Route
            path={'/conversations/:conversationId/report/internal'}
            element={
              <ProtectedRoute>
                <InternalConversationReport />
              </ProtectedRoute>
            }
          />
          <Route
            path={'/conversations/:conversationId/vote/external'}
            element={<ExternalConversationVote />}
          />
          <Route
            path={'/conversations/:conversationId/vote/internal'}
            element={<InternalConversationVote />}
          />
          <Route path='/login' element={<LoginPage />} />
          <Route
            path='*'
            element={
              <RedirectWithParams
                redirects={[
                  {
                    from: '/conversations/:conversationId/report',
                    to: '/conversations/:conversationId/report/external',
                  },
                ]}
              />
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
