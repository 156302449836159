import jwt, { InvalidTokenError } from 'jwt-decode';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { User, UserToken } from '../components/types/User';
import { request } from '../network';
import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { IconCircleCheckFilled, IconExclamationMark } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface AuthContextType {
  user?: User;
  loading: boolean;
  login: (email: string, password: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const { t } = useTranslation('authContext');
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      try {
        const user = jwt<{ user: User }>(accessToken).user;
        setUser(user);
        if (location.pathname === '/login') {
          navigate('/', { replace: true });
        }
      } catch (error) {
        if (error instanceof InvalidTokenError) {
          localStorage.removeItem('accessToken');
          navigate('/login');
        }
        console.error('Error decoding access token', error);
      }
    }
    setLoadingInitial(false);
  }, []);

  const { mutate } = useLoginUser({
    onSuccess: (userToken) => {
      const user = jwt(userToken.access_token) as User;
      setUser(user);
      localStorage.setItem('accessToken', userToken.access_token);
      notifications.show({
        title: t('loginStatus.successful'),
        message: t('loginMessage.success'),
        icon: <IconCircleCheckFilled />,
        color: 'green',
        autoClose: 5000,
      });
      navigate('/', { replace: true });
    },
    onError: (error: AxiosError) => {
      notifications.show({
        title: t('loginStatus.failed'),
        message: (error?.response?.data as { detail: string }).detail || t('loginMessage.failed'),
        icon: <IconExclamationMark />,
        color: 'red',
        autoClose: 5000,
      });
    },
    onSettled: () => setLoading(false),
  });

  function login(email: string, password: string): void {
    setLoading(true);
    mutate({ email, password });
  }

  function logout(): void {
    setUser(undefined);
    localStorage.removeItem('accessToken');
  }

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
    }),
    [user, loading],
  );

  return (
    <AuthContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthContext.Provider>
  );
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}

type LoginUserVariables = {
  email: string;
  password: string;
};

function useLoginUser<TError = AxiosError, TData = UserToken>(
  options?: UseMutationOptions<TData, TError, LoginUserVariables>,
): UseMutationResult<TData, TError, LoginUserVariables> {
  return useMutation<TData, TError, LoginUserVariables>({
    mutationKey: ['login'],
    mutationFn: async ({ email, password }: LoginUserVariables) => {
      const requestData = new FormData();

      requestData.append('username', email);
      requestData.append('password', password);

      const { data } = await request.post('/login', requestData);
      return data;
    },
    ...options,
  });
}
