import React, { useState } from 'react';
import {
  ActionIcon,
  createStyles,
  Divider,
  Flex,
  Menu,
  SegmentedControl,
  Text,
  Title,
} from '@mantine/core';
import { IconDots, IconHome, IconLogout } from '@tabler/icons-react';
import useAuth from '../contexts/AuthContext';
import { useIsMobile } from '../utils/breakpoints';
import i18n from 'i18next';

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: '#F2CB05',
    color: '#000',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.fn.smallerThan('md')]: {
      padding: '0.2rem',
    },
  },
  a: {
    textDecoration: 'none',
    color: 'inherit',
    ':hover': {
      textDecoration: 'underline',
    },
  },

  menuIcon: {
    marginLeft: 10,
  },
}));

export default function Header(): JSX.Element {
  const { classes } = useStyles();
  const { user, logout } = useAuth();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const isMobile = useIsMobile();

  return (
    <header className={classes.header}>
      <Flex align={'center'}>
        <ActionIcon component={'a'} href={'/'} size={'lg'} variant={'subtle'}>
          <IconHome color={'black'} />
        </ActionIcon>
        {user && !isMobile ? (
          <Text fz={'md'} ml={20}>
            {user.name}
          </Text>
        ) : null}
      </Flex>
      <Flex align={'center'}>
        <SegmentedControl
          mr={20}
          value={currentLanguage}
          onChange={(value) => {
            setCurrentLanguage(value);
            i18n.changeLanguage(value);
          }}
          data={[
            { label: 'SK', value: 'sk' },
            { label: 'EN', value: 'en' },
          ]}
        />
        <a href={'https://www.demdis.sk'} className={classes.a}>
          <Title order={1} m={0}>
            DEMDIS
          </Title>
        </a>
        {user && (
          <Menu>
            <Menu.Target>
              <ActionIcon className={classes.menuIcon}>
                <IconDots size={24} color={'black'} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>{user.name}</Menu.Label>
              <Divider />
              <Menu.Item onClick={logout} icon={<IconLogout color={'black'} />}>
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Flex>
    </header>
  );
}
