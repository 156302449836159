import React, { memo, useMemo } from 'react';

import CanvasJSReact from '@canvasjs/react-charts';
import { useTranslation } from 'react-i18next';
import { ConversationVoteType } from '../../types/Conversation';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function GraphOfVotesOverTime({ votes }: { votes: ConversationVoteType[] }): JSX.Element {
  const { t } = useTranslation('graphs');

  const dataPoints = useMemo(() => {
    if (!votes || votes.length === 0) {
      return [];
    }
    return votes
      .sort(
        (vote1, vote2) => new Date(vote1.createdAt).valueOf() - new Date(vote2.createdAt).valueOf(),
      )
      .map((vote, index) => ({
        x: new Date(vote.createdAt),
        y: index + 1,
      }));
  }, [votes]);

  const options = {
    theme: 'light2',
    animationEnabled: true,
    zoomEnabled: true,
    title: {
      text: t('votesOverTime'),
    },
    data: [
      {
        type: 'area',
        xValueFormatString: 'DD MMM YYYY, HH:mm',
        dataPoints: dataPoints,
      },
    ],
  };
  return <CanvasJSChart options={options} />;
}

export default memo(GraphOfVotesOverTime);
