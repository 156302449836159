export default function round(num: number, decimalPoints = 0): number {
  if (decimalPoints === 0) {
    return Math.round(num);
  }
  const val = +(Math.round(Number(num + `e+${decimalPoints}`)) + `e-${decimalPoints}`);
  if (val % 1 === 0) {
    return parseFloat(`${val}.0`);
  }
  return val;
}
