import React, { memo } from 'react';
import { ClusterVotePoint } from '../../types/ClusterVotePoint';
import { Circle, Image, Layer, Line, Rect, Stage, Text } from 'react-konva';
import useImage from 'use-image';
import { ClusteredConversationPoints } from '../../types/Conversation';
import { conversationColorFromName } from '../../../utils/conversationColor';
import { useResizeObserver } from '@mantine/hooks';
import { MantineNumberSize } from '@mantine/styles/lib/theme/types/MantineSize';

type Props = {
  points: ClusteredConversationPoints[];
  votingUserDatapoint: ClusterVotePoint | null;
  maxWidth?: MantineNumberSize;
};

const ClusterVotesPoints2D2DefaultProps: Partial<Props> = {
  maxWidth: '800px',
};

const ClusterVotesPoints2D2 = ({ points, votingUserDatapoint, maxWidth }: Props): JSX.Element => {
  const [ref, rect] = useResizeObserver();
  const width = rect.width || 1;
  const height = width * (3 / 4);
  const pointsCount = points.reduce((acc, curr) => acc + curr.points.length, 0);

  const yPointDistMultiplier =
    height /
    2.8 /
    Math.max(
      ...points.map((clusteredPoints) => Math.max(...clusteredPoints.points.map((vote) => vote.y))),
    );
  const xPointDistMultiplier =
    width /
    2.4 /
    Math.max(
      ...points.map((clusteredPoints) => Math.max(...clusteredPoints.points.map((vote) => vote.x))),
    );

  const radius = Math.max(2, Math.min(width / 2.5 / pointsCount + 2, 10));
  const labelPositions: { x: number; y: number; name: string }[] = points.map((clusteredPoints) => {
    let x = clusteredPoints.clusterCenter.x;
    let y = clusteredPoints.clusterCenter.y;

    while (
      clusteredPoints.points.filter(
        (point) =>
          Math.sqrt(
            (point.x * xPointDistMultiplier - x * xPointDistMultiplier) ** 2 +
              (point.y * yPointDistMultiplier - y * yPointDistMultiplier) ** 2,
          ) <
          100 / Math.log2(radius),
      ).length > 0 &&
      Math.abs(x * xPointDistMultiplier) < width / 2.3 &&
      Math.abs(y * yPointDistMultiplier) < height / 2.3
    ) {
      x *= 1.01;
      y *= 1.01;
    }

    return { x: x, y: y, name: clusteredPoints.name };
  });

  return (
    <div
      ref={ref}
      style={{ width: '100%', maxWidth: maxWidth || ClusterVotesPoints2D2DefaultProps.maxWidth }}
    >
      <Stage width={width} height={height} x={width / 2} y={height / 2}>
        <Layer listening={false}>
          <Rect
            x={-(width / 2)}
            y={-(height / 2)}
            width={width}
            height={height}
            fill={'#ffffff'}
            transformsEnabled='position'
          />
          <Line points={[-(width / 2), 0, width / 2, 0]} stroke={'#DEDEDE'} strokeWidth={1.5} />
          <Line points={[0, -(height / 2), 0, height / 2]} stroke={'#DEDEDE'} strokeWidth={1.5} />
          {points.map((clusteredPoints) =>
            clusteredPoints.points.map((point, index) => (
              <Circle
                key={clusteredPoints.name + index}
                x={point.x * xPointDistMultiplier}
                y={-point.y * yPointDistMultiplier}
                radius={radius}
                fill={conversationColorFromName(clusteredPoints.name)}
              />
            )),
          )}
          {labelPositions.map((label, index) => (
            <Text
              key={index}
              x={label.x * xPointDistMultiplier}
              y={-label.y * yPointDistMultiplier}
              text={label.name}
              fontSize={Math.max(20, window.screen.width / 50)}
              align={'center'}
              width={Math.max(20, window.screen.width / 50)}
              fill={conversationColorFromName(label.name)}
            />
          ))}
          {votingUserDatapoint !== null && (
            <UserDatapoint
              votingUserDatapoint={votingUserDatapoint}
              xPointDistMultiplier={xPointDistMultiplier}
              yPointDistMultiplier={yPointDistMultiplier}
              container_height={height}
            />
          )}
        </Layer>
      </Stage>
    </div>
  );
};

function UserDatapoint({
  votingUserDatapoint,
  xPointDistMultiplier,
  yPointDistMultiplier,
  container_height,
}: {
  votingUserDatapoint: ClusterVotePoint;
  xPointDistMultiplier: number;
  yPointDistMultiplier: number;
  container_height: number;
}): JSX.Element {
  const userPictureWidth = 40;
  const userPictureLineLength = 25;
  const topMarginForUserAvatar = 12;
  let vertical_direction = 1;
  const flip_breakoff_point =
    container_height / 2 +
    (-userPictureLineLength - topMarginForUserAvatar - userPictureWidth) * 1.5;
  if (votingUserDatapoint.y * yPointDistMultiplier > flip_breakoff_point) {
    vertical_direction = -1;
  }
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const [userAvatar] = useImage(require('../../../assets/user-avatar.png'));

  return (
    <>
      <Line
        points={[
          votingUserDatapoint.x * xPointDistMultiplier,
          -votingUserDatapoint.y * yPointDistMultiplier,
          votingUserDatapoint.x * xPointDistMultiplier,
          -votingUserDatapoint.y * yPointDistMultiplier -
            userPictureLineLength * vertical_direction,
        ]}
        stroke={conversationColorFromName(votingUserDatapoint.clusterName)}
        strokeWidth={5}
      />
      <Rect
        x={votingUserDatapoint.x * xPointDistMultiplier - userPictureWidth / 2}
        y={
          vertical_direction === -1
            ? -votingUserDatapoint.y * yPointDistMultiplier + userPictureLineLength
            : -votingUserDatapoint.y * yPointDistMultiplier -
              userPictureLineLength -
              userPictureWidth -
              topMarginForUserAvatar
        }
        width={userPictureWidth}
        height={userPictureWidth + topMarginForUserAvatar}
        cornerRadius={7}
        fill={conversationColorFromName(votingUserDatapoint.clusterName)}
        stroke={conversationColorFromName(votingUserDatapoint.clusterName)}
        strokeWidth={9}
        transformsEnabled='position'
      />
      <Image
        image={userAvatar}
        x={votingUserDatapoint.x * xPointDistMultiplier - userPictureWidth / 2}
        y={
          vertical_direction === -1
            ? -votingUserDatapoint.y * yPointDistMultiplier +
              userPictureLineLength +
              topMarginForUserAvatar
            : -votingUserDatapoint.y * yPointDistMultiplier -
              userPictureLineLength -
              userPictureWidth
        }
        cornerRadius={7}
        width={userPictureWidth}
        height={userPictureWidth}
      />
      <Text
        x={votingUserDatapoint.x * xPointDistMultiplier - userPictureWidth / 2}
        y={
          vertical_direction === -1
            ? -votingUserDatapoint.y * yPointDistMultiplier + userPictureLineLength
            : -votingUserDatapoint.y * yPointDistMultiplier -
              userPictureLineLength -
              userPictureWidth -
              topMarginForUserAvatar
        }
        width={userPictureWidth}
        height={topMarginForUserAvatar}
        text={'Vy'}
        fontSize={9}
        align={'center'}
        fill={'white'}
      />
    </>
  );
}

export default memo(ClusterVotesPoints2D2);
