import React from 'react';
import {
  ConversationStatisticsType,
  InternalReportSourceStats,
} from '../../../../components/types/Conversation';
import { useTranslation } from 'react-i18next';
import { createStyles, Table, Title } from '@mantine/core';

type SourceStatsTableProps = {
  label: string;
  statistics: ConversationStatisticsType | undefined;
  sourceStats: InternalReportSourceStats[] | undefined;
  isSourceStatsLoading: boolean;
};

function SourceStatsTable({
  label,
  statistics,
  sourceStats,
  isSourceStatsLoading,
}: SourceStatsTableProps): JSX.Element {
  const { classes, cx } = useStyles();
  const { t } = useTranslation('report');

  return (
    <div>
      <Title order={3}>{t(label)}</Title>
      {sourceStats && sourceStats.length > 0 && statistics ? (
        <div className={cx(classes.tableDiv)}>
          <Table
            className={cx(classes.root)}
            fontSize={'20px'}
            withBorder={false}
            highlightOnHover={true}
          >
            <thead>
              <tr>
                <th>{t('source')}</th>
                <th>{t('votes')}</th>
                <th>{t('comments')}</th>
                <th>{t('voters')}</th>
                <th>{t('groupedParticipants')}</th>
                {sourceStats
                  .at(0)
                  ?.clusters.map((cluster) => (
                    <th key={cluster.clusterName}>{cluster.clusterName}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {sourceStats.map((partner) => (
                <tr key={partner.name}>
                  <td>{partner.name}</td>
                  <td>{partner.votes}</td>
                  <td>{partner.comments}</td>
                  <td>{partner.participants}</td>
                  <td>{partner.clusteredParticipants}</td>
                  {partner.clusters.map((group) => (
                    <td key={group.clusterName}>{group.participants}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : isSourceStatsLoading ? (
        <div>{t('partnerStatsLoading')}</div>
      ) : (
        <div>{t('noPartnersForConversation')}</div>
      )}
    </div>
  );
}

const useStyles = createStyles(() => ({
  tableDiv: {
    overflow: 'overlay',
    width: 'fit-content',
    border: '1px solid black',
    borderRadius: '10px',
  },
  root: {
    borderWidth: '1px',
    borderColor: 'black',
    width: 'fit-content',

    '& > thead': {
      backgroundColor: '#000000',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'black',
      borderRadius: '6px 6px 0px 0px',
    },
    '& > thead > tr > th': {
      color: 'white',
    },
  },
}));

export default SourceStatsTable;
