import React from 'react';
import Header from '../components/Header';
import { useConversations } from '../network';
import { Badge, Card, Container, Divider, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export function Root(): JSX.Element {
  const { t } = useTranslation('conversations');
  const { data: conversations } = useConversations();

  return (
    <div>
      <Header />
      {conversations && (
        <Container fluid={true}>
          <Group spacing={10} align={'flex-start'}>
            {conversations.map((conversation) => (
              <Card
                key={conversation.id}
                w={{ base: '100%', sm: 300 }}
                shadow={'sm'}
                padding={'xs'}
                radius={'md'}
                withBorder={true}
              >
                <Text
                  size={'xl'}
                  display={'block'}
                  component={'a'}
                  href={`/conversations/${conversation.uuid}`}
                >
                  {conversation.id}. {conversation.name}
                </Text>
                <Badge
                  size={'md'}
                  radius={'sm'}
                  mt={5}
                  color={conversation.isActive ? 'green' : 'red'}
                >
                  {conversation.isActive ? t('open') : t('closed')}
                </Badge>
                <Divider my={'xs'} variant={'dotted'} />
                <Text size={'md'}>{conversation.createdAt}</Text>
                <Text size={'md'} lineClamp={4}>
                  {conversation.description || t('noDescription')}
                </Text>
              </Card>
            ))}
          </Group>
        </Container>
      )}
    </div>
  );
}
