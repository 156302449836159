import { Flex, Box, Title } from '@mantine/core';
import React from 'react';

export type StatProps = {
  label: string;
  value: number;
  icon?: React.ReactNode;
};

export function Stat({ label, value, icon }: StatProps): JSX.Element {
  return (
    <Box
      sx={(theme) => ({
        padding: '12px 15px',
        minWidth: 'fit-content',

        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        background: theme.colors.yellow[6],
        border: `2px solid ${theme.colors.dark[9]}`,
        borderRadius: 16,
      })}
      component='div'
    >
      <Title order={2}>{label}</Title>
      <Flex direction={'row'} align='center' gap={5}>
        {icon || null}
        <Title order={2}>{formatWholeNumber(value)}</Title>
      </Flex>
    </Box>
  );
}

function formatWholeNumber(value: number): string {
  let stringValue = String(value);
  if (stringValue.length > 3 && !stringValue.includes('.')) {
    const stringParts: string[] = [];
    for (let i = stringValue.length - 1; i >= 0; i = i - 3) {
      stringParts.push(stringValue.slice(Math.max(0, i - 2), i + 1));
    }
    stringValue = stringParts.reverse().join(' ');
  }
  return stringValue;
}
