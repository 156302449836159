import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { CommentBarGraph } from './CommentBarGraph';
import { GroupDefiningCommentType } from '../../types/Conversation';
import { conversationColorFromName } from '../../../utils/conversationColor';
import { ActionIcon, Center, createStyles, Flex, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

type Props = {
  clusterName: string;
  data: GroupDefiningCommentType;
};

interface StyleProps {
  color: string;
}

const useStyles = createStyles((theme, { color }: StyleProps) => ({
  cardWrapper: {
    width: '100%',
    height: 'fit-content',
    padding: '15px',
    paddingTop: '12px',

    backgroundColor: 'white',
    borderColor: color,
    borderWidth: '1.5px',
    borderStyle: 'solid',
    borderRadius: '12px',
  },
  text: {
    color: color,
    wordBreak: 'break-word',
  },
  groupPercentage: {
    fontSize: '10px',
    lineHeight: '11px',
  },
}));

export default function GroupDefiningCommentDisplay({ data, clusterName }: Props): JSX.Element {
  const { t } = useTranslation('groupDefDisplay');
  const color = conversationColorFromName(clusterName);
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const { classes } = useStyles({ color: color });

  const { text, agreementPercentage, groups } = data;

  const smallGraphHeight = 35;
  const bigGraphHeight = 80;
  const bigPillarWidth = 14;
  const smallPillarWidth = 6;
  const smallPillarPadding = 2;
  const bigPillarPadding = 12;

  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection={'horizontal'}
      infinite={true}
      flipSpeedBackToFront={0.8}
      flipSpeedFrontToBack={0.8}
      cardStyles={{ back: { overflow: 'hidden' }, front: { overflow: 'hidden' } }}
    >
      <div onClick={() => setIsFlipped(!isFlipped)} className={classes.cardWrapper}>
        <Flex justify={'space-between'}>
          <Text fz={'md'} fw={700} mt={3} className={classes.text}>
            {clusterName}: {t('weAgree')}
          </Text>
          <Flex>
            <CommentBarGraph
              commentStats={groups}
              numberOfCluster={groups.length}
              graphHeight={smallGraphHeight}
              pillarPadding={smallPillarPadding}
              pillarWidth={smallPillarWidth}
              expandGraph={false}
              showPercentage={false}
            />
            <Flex direction={'column'} ml={8} mt={2}>
              {groups.map((stat, index) => (
                <Text
                  key={index}
                  className={classes.groupPercentage}
                  c={conversationColorFromName(stat.name)}
                >
                  {stat.percentage}%
                </Text>
              ))}
            </Flex>
          </Flex>
        </Flex>
        <Text fz={'md'} mt={5} className={classes.text}>
          {text}
        </Text>
      </div>
      <Center onClick={() => setIsFlipped(!isFlipped)} className={classes.cardWrapper}>
        <Flex w={'100%'} top={0} pos={'fixed'} align={'start'} justify={'end'} mt={8} mr={15}>
          <ActionIcon onClick={() => setIsFlipped(!isFlipped)} variant={'transparent'}>
            <IconX size={25} color={'black'} />
          </ActionIcon>
        </Flex>
        <CommentBarGraph
          commentStats={groups}
          numberOfCluster={groups.length}
          graphHeight={bigGraphHeight}
          pillarPadding={bigPillarPadding}
          pillarWidth={bigPillarWidth}
          expandGraph={true}
          showPercentage={true}
        />
        <Text fz={'md'} ml={20} my={5} className={classes.text}>
          {agreementPercentage}% {t('group')} {clusterName} {t('agree')}
        </Text>
      </Center>
    </ReactCardFlip>
  );
}
