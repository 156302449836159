import React from 'react';
import ConversationVoting from './utils';
import ConversationVote from './ConversationVote';
import { useSearchParams } from 'react-router-dom';

export default function ExternalConversationVote(): JSX.Element {
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('external_conversation_user_id');

  if (userId === null) {
    console.error('Nespravne nastavena konverzacia (ziadne user ID)');
    return (
      <h1 style={{ textAlign: 'center' }}>Nespravne nastavena konverzacia (ziadne user ID)</h1>
    );
  }

  return (
    <ConversationVoting
      conversation={(conversationId: string) => (
        <ConversationVote conversationId={conversationId} conversationUserId={userId} />
      )}
    />
  );
}
