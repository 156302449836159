import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Chip,
  CopyButton,
  createStyles,
  Divider,
  Flex,
  Group,
  Modal,
  Paper,
  Skeleton,
  Stack,
  Table,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IconCheck, IconCopy, IconDownload } from '@tabler/icons-react';
import i18next from 'i18next';
import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, Line, Rect, Stage } from 'react-konva';

import * as htmlToImage from 'html-to-image';
import round from '../../../utils/numberUtils';
import { CommentInClusterType, ConsensusCommentReportType } from '../../types/Conversation';
import { getConsensusFromClusterComments } from '../../../utils/comments';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const demdisLogo = require('../../../assets/demdis-logo-small-trimmed.png');

export type StatementsTableProps = {
  comments: ConsensusCommentReportType[];
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  commentIdToHighlight: string | null;
  showCopyButton: boolean;
};

StatementsTable.defaultProps = {
  commentIdToHighlight: null,
  showCopyButton: false,
};

export function StatementsTable({
  comments,
  commentIdToHighlight,
  sortBy,
  setSortBy,
  showCopyButton,
}: StatementsTableProps): JSX.Element {
  const { t } = useTranslation('report');
  const statementsRefs = useRef<HTMLTableRowElement[] | null[]>([]);
  const [downloadIndex, setDownloadIndex] = React.useState<number | null>(null);
  const { cx, classes } = useTableStyles();

  const getCopyURLBasedOnCommentId = (commentId: number): string => {
    let url = window.location.href;
    if (url.includes('comment_id')) {
      const base = url.split('comment_id=')[0];
      url = base + `comment_id=${commentId}`;
    } else {
      url = url + `?comment_id=${commentId}`;
    }
    return url;
  };

  useEffect(() => {
    if (commentIdToHighlight && statementsRefs) {
      const el = statementsRefs.current[Number(commentIdToHighlight)];
      if (el) {
        setTimeout(() => {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          el.animate([{ backgroundColor: 'rgb(255, 255, 60, 0.8)' }], {
            duration: 2000,
            easing: 'linear',
          });
        }, 1000);
      }
    }
  }, [commentIdToHighlight, comments, statementsRefs]);

  return (
    <>
      {downloadIndex != null && comments.length > 0 && (
        <Box pos={'absolute'}>
          <StatementExport
            statement={comments[downloadIndex].text}
            comment={comments[downloadIndex]}
            onClose={() => setDownloadIndex(null)}
          />
        </Box>
      )}
      <div>
        <div className={cx(classes.tableDiv)}>
          <Table
            highlightOnHover={true}
            striped={false}
            captionSide={'bottom'}
            fontSize={'sm'}
            horizontalSpacing={'xs'}
            verticalSpacing={'xs'}
          >
            <thead>
              <tr>
                <th className={cx(classes.idTableHeader)} onClick={() => setSortBy('id')}>
                  <Text fw={sortBy == 'id' ? 700 : 500} underline={sortBy == 'id'}>
                    ID
                  </Text>
                </th>
                <th className={cx(classes.textTableHeader)}>
                  <Text fw={500}>Text</Text>
                </th>
                <th onClick={() => setSortBy('consensus')}>
                  <Text fw={sortBy == 'consensus' ? 700 : 500} underline={sortBy == 'consensus'}>
                    {t('consensus')} {sortBy == 'consensus' && '↓'}
                  </Text>
                </th>
                {comments.length > 0 &&
                  comments[0].groups.map((group, index) => {
                    const selected = sortBy == group.clusterName;
                    return (
                      <th onClick={() => setSortBy(group.clusterName)} key={index}>
                        <Text fw={selected ? 700 : 500} underline={selected}>
                          {group.clusterName} {selected && '↓'}
                        </Text>
                      </th>
                    );
                  })}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {comments.map((comment, index) => (
                <tr key={comment.id} ref={(el) => (statementsRefs.current[comment.id] = el)}>
                  <ConsensusCommentReport comment={comment} />
                  <td>
                    <ActionIcon onClick={() => setDownloadIndex(index)}>
                      <IconDownload size={16} />
                    </ActionIcon>
                    {showCopyButton && (
                      <CopyButton value={getCopyURLBasedOnCommentId(comment.id)} timeout={1000}>
                        {({ copied, copy }) => (
                          <Tooltip
                            label={copied ? t('copied') : t('copy')}
                            withArrow={true}
                            position={'right'}
                          >
                            <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                              {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </CopyButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

const useTableStyles = createStyles(() => ({
  tableDiv: {
    overflowX: 'scroll',
  },
  idTableHeader: {
    width: '50px',
    minWidth: '50px',
  },
  textTableHeader: {
    minWidth: '400px',
  },
}));

const ConsensusCommentReport = memo(function ConsensusCommentReport({
  comment,
}: {
  comment: ConsensusCommentReportType;
}): JSX.Element {
  const consensusCommentInCluster = getConsensusFromClusterComments(comment);

  return (
    <>
      <td>{comment.id}</td>
      <td style={{ maxWidth: '450px' }}>{comment.text}</td>
      <td>
        <CommentInCluster comment={consensusCommentInCluster} />
      </td>
      {comment.groups.map((group, index) => (
        <td key={index}>
          <CommentInCluster key={index} comment={group} />
        </td>
      ))}
    </>
  );
});

const CommentInCluster = memo(function CommentInCluster({
  comment,
}: {
  comment: CommentInClusterType;
}): JSX.Element {
  const { t } = useTranslation('common');
  const total =
    comment.agreeCount + comment.disagreeCount + comment.skippedCount + comment.unseenCount;
  const totalVotes = total - comment.unseenCount;

  const barWidth = 110;
  const barHeight = 10;
  const borderWidth = 1;
  const barInnerWidth = barWidth - borderWidth * 2;

  const agreeWidth = (comment.agreeCount / total) * barInnerWidth;
  const disagreeWidth = (comment.disagreeCount / total) * barInnerWidth;
  const skippedWidth = (comment.skippedCount / total) * barInnerWidth;

  return (
    <Tooltip
      label={`${i18next.format(t('agreed'), 'capitalize')}: ${
        comment.agreeCount
      } | ${i18next.format(t('disagreed'), 'capitalize')}: ${
        comment.disagreeCount
      } | ${i18next.format(t('skipped'), 'capitalize')}: ${comment.skippedCount} | ${i18next.format(
        t('unseen'),
        'capitalize',
      )}: ${comment.unseenCount}`}
    >
      <Flex direction={'column'}>
        <Stage width={barWidth} height={barHeight}>
          <Layer listening={false}>
            <Line
              points={[0, 0, barWidth, 0, barWidth, barHeight, 0, barHeight, 0, 0]}
              stroke={'#000000'}
              strokeWidth={borderWidth}
            />
            <Rect
              x={borderWidth}
              y={borderWidth}
              width={agreeWidth}
              height={barHeight - borderWidth * 2}
              fill={'#65c97a'}
              transformsEnabled='position'
            />
            <Rect
              x={agreeWidth + borderWidth}
              y={borderWidth}
              width={disagreeWidth}
              height={barHeight - borderWidth * 2}
              fill={'#d65745'}
              transformsEnabled='position'
            />
            <Rect
              x={agreeWidth + disagreeWidth + borderWidth}
              y={borderWidth}
              width={skippedWidth}
              height={barHeight - borderWidth * 2}
              fill={'#e6e6e6'}
              transformsEnabled='position'
            />
          </Layer>
        </Stage>
        <Group spacing={4} mt={4} align={'end'}>
          <Text fz={'xs'} span={true} c={'green'}>
            {round((comment.agreeCount / totalVotes) * 100)}%
          </Text>
          <Text fz={'xs'} span={true} c={'red'}>
            {round((comment.disagreeCount / totalVotes) * 100)}%
          </Text>
          <Text fz={'xs'} span={true} c={'gray'}>
            {round((comment.skippedCount / totalVotes) * 100)}%
          </Text>
          <Text fz={'xs'} ml={5} span={true} c={'gray'}>
            ({totalVotes})
          </Text>
        </Group>
      </Flex>
    </Tooltip>
  );
});

const StatementExport = memo(function StatementExport({
  statement,
  comment,
  onClose,
}: {
  statement: string;
  comment: ConsensusCommentReportType;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation(['report', 'common']);
  const theme = useMantineTheme();
  const ref = useRef<HTMLDivElement | null>(null);

  const [showTitle, setShowTitle] = React.useState<boolean>(true);
  const [showLegend, setShowLegend] = React.useState<boolean>(true);
  const [exportConsensus, setExportConsensus] = React.useState<boolean>(false);
  const [exportClusterIndexes, setExportClusterIndexes] = React.useState<number[]>(
    comment.groups.map((_, index) => index),
  );

  const consensus = getConsensusFromClusterComments(comment);
  const clusters = comment.groups;

  return (
    <Modal opened={true} onClose={onClose} size={690} p={20}>
      <Flex gap={20} mb={10}>
        <Checkbox
          checked={showTitle}
          onChange={() => setShowTitle(!showTitle)}
          label={t('showTitle')}
          styles={{
            label: { paddingLeft: '0.5rem' },
          }}
        />
        <Checkbox
          checked={showLegend}
          onChange={() => setShowLegend(!showLegend)}
          label={t('showLegend')}
          styles={{
            label: { paddingLeft: '0.5rem' },
          }}
        />
      </Flex>
      <Flex gap={10}>
        <Chip checked={exportConsensus} onChange={() => setExportConsensus(!exportConsensus)}>
          {t('report:together')}
        </Chip>
        {clusters.map(({ clusterName }, index) => (
          <Chip
            key={index}
            checked={exportClusterIndexes.includes(index)}
            onChange={() => {
              if (exportClusterIndexes.includes(index)) {
                setExportClusterIndexes(exportClusterIndexes.filter((i) => i != index));
              } else {
                setExportClusterIndexes([...exportClusterIndexes, index]);
              }
            }}
          >
            {`${clusterName} Group`}
          </Chip>
        ))}
      </Flex>
      <Divider my={10} />
      <Paper w={630} p={20} ref={ref} radius={0} withBorder={true}>
        {showTitle && (
          <Text mb={17} fz={'xl'}>
            {statement}
          </Text>
        )}
        <Stack spacing={7}>
          {exportConsensus && (
            <Flex direction={'column'}>
              <Text fz={'xs'} c={'gray'}>
                {t('report:together')}
              </Text>
              <StatementExportBar
                agreeCount={consensus.agreeCount}
                disagreeCount={consensus.disagreeCount}
                skippedCount={consensus.skippedCount}
              />
            </Flex>
          )}
          {clusters
            .filter((_, index) => exportClusterIndexes.includes(index))
            .map(({ clusterName, agreeCount, disagreeCount, skippedCount }, index) => (
              <Flex direction={'column'} key={index}>
                <Text fz={'xs'} c={'gray'}>{`${t('report:group')} ${clusterName}`}</Text>
                <StatementExportBar
                  agreeCount={agreeCount}
                  disagreeCount={disagreeCount}
                  skippedCount={skippedCount}
                />
              </Flex>
            ))}
        </Stack>
        <Flex mt={12} justify={'space-between'} align={'center'}>
          {showLegend ? (
            <Flex direction={'row'} gap={13}>
              <StatementExportLegend
                color={theme.colors.green[6]}
                legend={i18next.format(t('common:agreed'), 'capitalize')}
              />
              <StatementExportLegend
                color={theme.colors.red[6]}
                legend={i18next.format(t('common:disagreed'), 'capitalize')}
              />
              <StatementExportLegend
                color={theme.colors.gray[6]}
                legend={i18next.format(t('common:skipped'), 'capitalize')}
              />
            </Flex>
          ) : (
            <span />
          )}
          <img height={14} src={demdisLogo} alt={'DEMDIS logo'} />
        </Flex>
      </Paper>
      <Button
        w={'100%'}
        h={50}
        onClick={() => {
          const current = ref.current;
          if (current == null) {
            return;
          }
          htmlToImage.toPng(current).then((dataUrl: string) => {
            const link = document.createElement('a');
            link.download = `${statement} - cluster export.png`;
            link.href = dataUrl;
            link.click();
            onClose();
          });
        }}
      >
        <IconDownload />
      </Button>
    </Modal>
  );
});

const StatementExportLegend = memo(function StatementExportLegend({
  color,
  legend,
}: {
  color: string;
  legend: string;
}): JSX.Element {
  return (
    <Flex direction={'row'} align={'baseline'} gap={3}>
      <Box
        sx={() => ({
          width: '10px',
          height: '10px',
          marginRight: '3px',

          borderRadius: '1px',
          background: color,
        })}
      />
      <Text fz={'11px'}>{legend}</Text>
    </Flex>
  );
});

const StatementExportBar = memo(function StatementExportBar({
  agreeCount,
  disagreeCount,
  skippedCount,
}: {
  agreeCount: number;
  disagreeCount: number;
  skippedCount: number;
}): JSX.Element {
  const theme = useMantineTheme();
  const total = agreeCount + disagreeCount + skippedCount;

  const gapCount = Math.max(
    (agreeCount === 0 ? 0 : 1) + (disagreeCount === 0 ? 0 : 1) + (skippedCount === 0 ? 0 : 1) - 1,
    0,
  );

  const barWidth = 576;
  const barHeight = 24;
  const barGap = 6;

  let agreeWidth = (agreeCount / total) * barWidth;
  let disagreeWidth = (disagreeCount / total) * barWidth;
  let skippedWidth = (skippedCount / total) * barWidth;

  const validCount =
    (isNaN(agreeWidth) || agreeWidth == 0 ? 0 : 1) +
    (isNaN(disagreeWidth) || disagreeWidth == 0 ? 0 : 1) +
    (isNaN(skippedWidth) || skippedWidth == 0 ? 0 : 1);

  if (gapCount < 2) {
    if (agreeWidth > 0) {
      agreeWidth += (2 * barGap) / (gapCount + 1) / validCount;
    }
    if (disagreeWidth > 0) {
      disagreeWidth += (2 * barGap) / (gapCount + 1) / validCount;
    }
    if (skippedWidth > 0) {
      skippedWidth += (2 * barGap) / (gapCount + 1) / validCount;
    }
  }

  return (
    <Flex w={barWidth + 2 * barGap} gap={barGap}>
      {validCount == 0 && (
        <Box
          w={barWidth + 2 * barGap}
          h={barHeight}
          sx={(theme) => ({
            background: `repeating-linear-gradient(-55deg, ${theme.colors.gray[2]}, ${theme.colors.gray[2]} 10px, ${theme.colors.gray[5]} 10px, ${theme.colors.gray[5]} 20px)`,
          })}
        />
      )}
      {agreeWidth > 0 && (
        <Box w={agreeWidth} display={'grid'}>
          <Box
            w={agreeWidth}
            h={barHeight}
            bg={'green'}
            style={{
              gridColumn: 1,
              gridRow: 1,
            }}
          />
          <div
            style={{
              gridColumn: 1,
              gridRow: 1,
              display: 'flex',
              flexFlow: 'column wrap',

              marginLeft: '6px',
            }}
          >
            <Text c={'white'}>{Math.round((agreeCount / total) * 100)}%</Text>
          </div>
        </Box>
      )}
      {disagreeWidth > 0 && (
        <Box w={disagreeWidth} display={'grid'}>
          <Box
            w={disagreeWidth}
            h={barHeight}
            bg={'red'}
            style={{
              gridColumn: 1,
              gridRow: 1,
            }}
          />
          <div
            style={{
              gridColumn: 1,
              gridRow: 1,
              display: 'flex',
              flexFlow: 'column wrap',

              marginLeft: '6px',
            }}
          >
            <Text c={'white'}>{Math.round((disagreeCount / total) * 100)}%</Text>
          </div>
        </Box>
      )}
      {skippedWidth > 0 && (
        <Box w={skippedWidth} display={'grid'}>
          <Box
            w={skippedWidth}
            h={barHeight}
            bg={theme.colors.gray[4]}
            style={{
              gridColumn: 1,
              gridRow: 1,
            }}
          />
          <div
            style={{
              gridColumn: 1,
              gridRow: 1,
              display: 'flex',
              flexFlow: 'column wrap',

              marginLeft: '6px',
            }}
          >
            <Text c={theme.colors.gray[9]}>{Math.round((skippedCount / total) * 100)}%</Text>
          </div>
        </Box>
      )}
    </Flex>
  );
});

export const StatementsTableSkeleton = memo(function StatementsTableSkeleton(): JSX.Element {
  const skeletonLength = 20;
  return (
    <div>
      {Array.from({ length: skeletonLength }).map((_, index) => (
        <React.Fragment key={index}>
          <Flex my={'10px'}>
            <Skeleton width={30} height={30} mr={10} />
            <Skeleton width={400} height={30} mr={10} />
            <Skeleton width={100} height={30} mr={10} />
            <Skeleton width={100} height={30} mr={10} />
            <Skeleton width={100} height={30} mr={10} />
          </Flex>
          {index < skeletonLength && <Divider />}
        </React.Fragment>
      ))}
    </div>
  );
});
