import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  Badge,
  Button,
  createStyles,
  Flex,
  Group,
  MantineColor,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  useConversationReportCustomCluster,
  useConversationReportSourcesList,
} from '../../../../network';
import {
  ClusteredConversationPoints,
  ConversationParticipantType,
  ConversationReportClusterDataComments,
  ConversationReportClusterDataType,
  ConversationSourceTypeEnum,
  ConversationVoteType,
} from '../../../../components/types/Conversation';
import GraphOfVotesOverTime from '../../../../components/dataVisualized/charts/GraphOfVotesOverTime';
import GraphOfParticipantsOverTime from '../../../../components/dataVisualized/charts/GraphOfParticipantsOverTime';
import { StatementsTable } from '../../../../components/dataVisualized/commentBasedData/StatementsTable';
import ClusterVotesPoints2D2 from '../../../../components/dataVisualized/charts/ClusterVotesPoints2D2';
import { useIsMobile } from '../../../../utils/breakpoints';

const useStyles = createStyles(() => ({
  control: {
    fontSize: '20px',
    paddingLeft: 0,
  },
  content: {
    padding: 0,
  },
}));

interface CustomConversationClusterProps {
  conversationId: string;
}

function CustomConversationCluster({
  conversationId,
}: CustomConversationClusterProps): JSX.Element {
  const { t } = useTranslation('report');
  const mutation = useConversationReportCustomCluster(conversationId);
  const [clusterData, setClusterData] = useState<ConversationReportClusterDataType | null>();
  const [accordionState, setAccordionState] = useState<string[]>(['map', 'statements']);
  const { classes, cx } = useStyles();

  return (
    <>
      <DataSourcePicker
        conversationId={conversationId}
        runningClustering={mutation.isPending}
        runClustering={(internalSources, externalSources) => {
          mutation.mutate(
            { internalSources, externalSources },
            {
              onSuccess: (data) => {
                setClusterData(data);
              },
            },
          );
        }}
      />
      {mutation.isPending ? (
        <Text>{t('clusteringRunning')}</Text>
      ) : clusterData === null ? (
        <Text>{t('clusteringFailed')}</Text>
      ) : clusterData === undefined ? (
        <Text>{t('pickDataSourceToViewData')}</Text>
      ) : (
        <>
          <Accordion
            classNames={{
              control: cx(classes.control),
              content: cx(classes.content),
            }}
            chevronPosition={'left'}
            multiple={true}
            variant={'default'}
            value={accordionState}
            onChange={setAccordionState}
          >
            <GraphsAccordion
              votes={clusterData.votes}
              participants={clusterData.participants}
              isVisible={accordionState.includes('graphs')}
            />
            <VoteMapAccordion points={clusterData.clusterPoints} />
            <StatementsTableAccordion commentsMap={clusterData.comments} />
          </Accordion>
        </>
      )}
    </>
  );
}

const useDataSourcePickerStyle = createStyles(() => ({
  badge: {
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

interface DataSourcePickerProps {
  conversationId: string;
  runningClustering: boolean;
  runClustering: (internalSources: string[], externalSources: string[]) => void;
}

function DataSourcePicker({
  conversationId,
  runningClustering,
  runClustering,
}: DataSourcePickerProps): JSX.Element {
  const { t } = useTranslation('report');
  const [selectedExternalSources, setSelectedExternalSources] = useState<string[]>([]);
  const [selectedInternalSources, setSelectedInternalSources] = useState<string[]>([]);
  const {
    data: externalSources,
    isLoading: isExternalSourcesLoading,
    isSuccess: isExternalSourcesSucces,
  } = useConversationReportSourcesList(conversationId, ConversationSourceTypeEnum.EXTERNAL_SOURCE);
  const {
    data: internalSources,
    isLoading: isInternalSourcesLoading,
    isSuccess: isInternalSourcesSucces,
  } = useConversationReportSourcesList(conversationId, ConversationSourceTypeEnum.INTERNAL_SOURCE);

  useEffect(() => {
    externalSources && setSelectedExternalSources(externalSources);
  }, [isExternalSourcesSucces]);

  useEffect(() => {
    internalSources && setSelectedInternalSources(internalSources);
  }, [isInternalSourcesSucces]);
  const toggleAllClicked = (): void => {
    if (externalSources == null || internalSources == null) {
      return;
    }
    if (selectedExternalSources.length > 0 || selectedInternalSources.length > 0) {
      setSelectedExternalSources([]);
      setSelectedInternalSources([]);
    } else {
      setSelectedExternalSources(externalSources);
      setSelectedInternalSources(internalSources);
    }
  };
  const sourceClicked = (source: string, isExternal: boolean): void => {
    const sources = isExternal ? selectedExternalSources : selectedInternalSources;
    const setSource = isExternal ? setSelectedExternalSources : setSelectedInternalSources;
    if (sources.includes(source)) {
      setSource(sources.filter((p) => p !== source));
    } else {
      setSource([...sources, source]);
    }
  };

  return (
    <Stack spacing='xs' align='flex-start'>
      <Text fz='xl' fw={700}>
        {t('pickDataSourceToViewData')}
      </Text>
      <Flex gap={25} align='flex-end' wrap={'wrap'}>
        <Flex gap={25} align='flex-end'>
          {isExternalSourcesLoading ? (
            <div>{t('partnerStatsLoading')}</div>
          ) : externalSources && externalSources.length > 0 ? (
            <SourceBadges
              title={t('external')}
              badgeColor='dark'
              sources={externalSources}
              selectedSources={selectedExternalSources}
              sourceClicked={(source) => sourceClicked(source, true)}
            />
          ) : null}
          {isInternalSourcesLoading ? (
            <div>{t('internalStatsLoading')}</div>
          ) : internalSources && internalSources.length > 0 ? (
            <SourceBadges
              title={t('internal')}
              badgeColor='yellow'
              sources={internalSources}
              selectedSources={selectedInternalSources}
              sourceClicked={(source) => sourceClicked(source, false)}
            />
          ) : null}
        </Flex>
        {externalSources && internalSources ? (
          <Button variant='outline' compact={true} onClick={() => toggleAllClicked()}>
            {t('selectEverything')}
          </Button>
        ) : null}
      </Flex>
      <Button
        variant='outline'
        color='red'
        loading={runningClustering}
        disabled={selectedExternalSources.length === 0 && selectedInternalSources.length === 0}
        onClick={() => runClustering(selectedInternalSources, selectedExternalSources)}
      >
        {runningClustering ? t('clustering') : t('runClustering')}
      </Button>
    </Stack>
  );
}

function SourceBadges({
  title,
  sources,
  selectedSources,
  sourceClicked,
  badgeColor,
}: {
  title: string;
  sources: string[];
  selectedSources: string[];
  sourceClicked: (source: string) => void;
  badgeColor: MantineColor;
}): JSX.Element {
  const { classes, cx } = useDataSourcePickerStyle();

  return (
    <Stack spacing={5}>
      <Text>{title}</Text>
      <Group spacing={5}>
        {sources.map((source) => (
          <Badge
            onClick={() => sourceClicked(source)}
            className={cx(classes.badge)}
            variant={selectedSources.includes(source) ? 'filled' : 'outline'}
            color={badgeColor}
            key={source}
          >
            {source}
          </Badge>
        ))}
      </Group>
    </Stack>
  );
}

function GraphsAccordion({
  votes,
  participants,
  isVisible,
}: {
  votes: ConversationVoteType[];
  participants: ConversationParticipantType[];
  isVisible: boolean;
}): JSX.Element {
  const theme = useMantineTheme();
  theme.breakpoints;

  return (
    <Accordion.Item value='graphs'>
      <Accordion.Control>Graphs</Accordion.Control>
      <Accordion.Panel>
        <SimpleGrid
          breakpoints={[{ maxWidth: theme.breakpoints.md, cols: 1 }]}
          cols={2}
          spacing={'xl'}
          key={Number(isVisible)}
        >
          <GraphOfVotesOverTime votes={votes} />
          <GraphOfParticipantsOverTime participants={participants} />
        </SimpleGrid>
      </Accordion.Panel>
    </Accordion.Item>
  );
}

function VoteMapAccordion({ points }: { points: ClusteredConversationPoints[] }): JSX.Element {
  return (
    <Accordion.Item value='map'>
      <Accordion.Control>Map</Accordion.Control>
      <Accordion.Panel>
        <ClusterVotesPoints2D2 points={points} votingUserDatapoint={null} />
      </Accordion.Panel>
    </Accordion.Item>
  );
}

function StatementsTableAccordion({
  commentsMap,
}: {
  commentsMap: ConversationReportClusterDataComments;
}): JSX.Element {
  const [sortBy, setSortBy] = useState('consensus');
  const isMobile = useIsMobile();
  const { classes } = useStatementsTableAccordionStyle({ isMobile });

  return (
    <Accordion.Item value='statements'>
      <Accordion.Control>Statements</Accordion.Control>
      <Accordion.Panel className={classes.root}>
        <StatementsTable
          comments={commentsMap[sortBy] ?? []}
          sortBy={sortBy}
          setSortBy={(sortBy) => setSortBy(sortBy)}
        />
      </Accordion.Panel>
    </Accordion.Item>
  );
}

const useStatementsTableAccordionStyle = createStyles((_, isMobile: { isMobile: boolean }) => ({
  root: {
    width: isMobile ? '100%' : 'fit-content',
  },
}));

export default CustomConversationCluster;
