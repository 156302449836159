import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  useClusteredConversationConsensusComments,
  useClusteredConversationGroupDefiningComments,
  useClusteredConversationPoints,
  useConversationClusterUpdateDate,
  useConversationUserLocation,
  useIsConversationActive,
  usePolisConversationId,
} from '../../../network';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Flex,
  Popover,
  Skeleton,
  Text,
} from '@mantine/core';
import { Carousel, Embla } from '@mantine/carousel';
import { conversationColorFromName } from '../../../utils/conversationColor';
import ConsensusCommentDisplay from '../../../components/dataVisualized/commentBasedData/ConsensusCommentDisplay';
import GroupDefiningCommentDisplay from '../../../components/dataVisualized/commentBasedData/GroupDefiningCommentDisplay';
import ClusterVotesPoints2D2 from '../../../components/dataVisualized/charts/ClusterVotesPoints2D2';
import i18next from 'i18next';
import FacebookLogin, { ProfileSuccessResponse } from '@greatsumini/react-facebook-login';
import { IconInfoCircle } from '@tabler/icons-react';
import { keepPreviousData } from '@tanstack/react-query';
import useScript from 'react-script-hook';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../utils/breakpoints';

interface ConversationVoteProps {
  conversationId: string;
  conversationUserId: string;
  facebookLogin?: (response: ProfileSuccessResponse) => void;
  facebookLogout?: () => void;
  facebookName?: string | null;
  facebookPicture?: string | null;
}

const REQUEST_PERIOD = 5000;

const useStyles = createStyles(() => ({
  clusterButton: {
    height: 31,
    padding: 0,

    fontSize: 15,
    textAlign: 'center',

    color: '#5E5E5E',
    borderRadius: 4,
    borderColor: '#B8B8B8',
    borderWidth: 1,
    borderStyle: 'solid',
    background: '#F0F0F0',
  },
  consensusButton: {
    width: 100,
    marginRight: 24,
  },
  groupButton: {
    width: 33,
    marginLeft: 4,
    marginRight: 4,
  },
  clusterButtonActive: {
    backgroundColor: '#0981F0',
    color: '#ffffff',
  },
}));

export default function ConversationVote({
  conversationId,
  conversationUserId,
  facebookLogin,
  facebookLogout,
  facebookName,
  facebookPicture,
}: ConversationVoteProps): JSX.Element {
  const { classes, cx } = useStyles();

  const [selectedClusterName, setSelectedCluster] = useState<string | null>(null);
  const polisInit = useRef<boolean | null>(null);

  const { data: conversationUpdateDate } = useConversationClusterUpdateDate(conversationId, {
    refetchInterval: REQUEST_PERIOD,
  });

  const { data: isActive } = useIsConversationActive(conversationId);
  const { data: polisConversationId } = usePolisConversationId(conversationId);

  const { data: consensusComments } = useClusteredConversationConsensusComments(conversationId, {
    enabled: conversationUpdateDate !== undefined && polisConversationId !== null,
  });
  const { data: groupDefiningComments } = useClusteredConversationGroupDefiningComments(
    conversationId,
    { enabled: conversationUpdateDate !== undefined && polisConversationId !== null },
  );
  const { data: clusteredConversationPoints } = useClusteredConversationPoints(
    conversationId,
    conversationUpdateDate,
    {
      enabled: conversationUpdateDate !== undefined && polisConversationId !== null,
      placeholderData: keepPreviousData,
    },
  );
  const { data: userLocation } = useConversationUserLocation(
    conversationId,
    conversationUserId,
    conversationUpdateDate,
    {
      enabled: conversationUpdateDate !== undefined && polisConversationId !== null,
      placeholderData: keepPreviousData,
    },
  );

  const isConversationClustered = useMemo(() => {
    return clusteredConversationPoints !== null;
  }, [clusteredConversationPoints]);

  const maxCommentLength = useMemo(() => {
    if (consensusComments == null || groupDefiningComments == null) {
      return 0;
    }
    return Math.max(
      ...consensusComments.map((comment) => comment.text.length),
      ...groupDefiningComments.map((group) =>
        Math.max(...group.comments.map((comment) => comment.text.length)),
      ),
    );
  }, [consensusComments, groupDefiningComments]);

  const iframeRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const language = i18next.language;

  window.addEventListener(
    'message',
    (messageEvent) => {
      handlePolisEvent(
        messageEvent,
        iframeRef,
        containerRef,
        (isOk: boolean) => (polisInit.current = isOk),
      );
    },
    false,
  );
  useEffect(() => {
    postContainerHeight(containerRef);
  }, [conversationUpdateDate]);

  useScript({
    src: polisConversationId && isActive ? `${process.env.REACT_APP_POLIS_URL}/embed.js` : null,
  });

  return (
    <Center ref={containerRef} pt={10} w={{ base: '100%', sm: 'unset' }}>
      <Flex
        maw={750}
        w={'100%'}
        mx={{ base: 5, sm: 0 }}
        align={'center'}
        direction={'column'}
        justify={'center'}
      >
        {facebookLogin && facebookLogout && (
          <FacebookLoginButton
            userOrigin={conversationUserId}
            onSuccess={facebookLogin}
            logout={facebookLogout}
          />
        )}
        {isActive === false ? null : polisConversationId ? (
          <div
            className='polis'
            style={{ width: '100%', height: '300px' }}
            ref={iframeRef}
            data-conversation_id={polisConversationId}
            data-ucsv={false}
            data-ui_lang={language}
            data-border={'none'}
            data-xid={conversationUserId}
            data-x_name={facebookName}
            data-x_profile_image_url={facebookPicture}
          ></div>
        ) : (
          <PolisSkeleton />
        )}
        {clusteredConversationPoints && isConversationClustered ? (
          <ClusterVotesPoints2D2
            points={clusteredConversationPoints}
            votingUserDatapoint={userLocation || null}
          />
        ) : isConversationClustered ? (
          <Skeleton w={{ base: '100%', sm: 500 }} h={{ base: 200, sm: 360 }} />
        ) : (
          <div>No clusters</div>
        )}
        {consensusComments && groupDefiningComments && isConversationClustered ? (
          <>
            <Flex mt={20} mb={26} style={{ zIndex: 1 }}>
              <button
                className={cx(classes.clusterButton, classes.consensusButton, {
                  [classes.clusterButtonActive]: selectedClusterName === null,
                })}
                onClick={() => setSelectedCluster(null)}
              >
                Konsenzus
              </button>
              <Flex miw={'fit-content'} ml={24}>
                {groupDefiningComments.map((group) => (
                  <button
                    key={group.name}
                    onClick={() => setSelectedCluster(group.name)}
                    className={cx(classes.clusterButton, classes.groupButton, {
                      [classes.clusterButtonActive]: selectedClusterName === group.name,
                    })}
                  >
                    {group.name}
                  </button>
                ))}
              </Flex>
            </Flex>
            <Box w={{ base: '100%', sm: 500 }} h={'fit-content'}>
              <CommentsCarousel
                maxCommentLength={maxCommentLength}
                color={
                  selectedClusterName === null
                    ? '#000000'
                    : conversationColorFromName(selectedClusterName)
                }
              >
                {selectedClusterName === null
                  ? consensusComments.map((consensusComment, index) => (
                      <ConsensusCommentDisplay
                        key={index}
                        text={consensusComment.text}
                        meanAgreementPercentage={consensusComment.meanAgreementPercentage}
                        groups={consensusComment.groups}
                      />
                    ))
                  : groupDefiningComments
                      .filter((group) => group.name === selectedClusterName)[0]
                      .comments.map((consensusComment, index) => (
                        <GroupDefiningCommentDisplay
                          key={selectedClusterName + index}
                          clusterName={selectedClusterName}
                          data={consensusComment}
                        />
                      ))}
              </CommentsCarousel>
            </Box>
          </>
        ) : isConversationClustered ? (
          <CommentsCarouselSkeleton />
        ) : null}
      </Flex>
    </Center>
  );
}

function FacebookLoginButton({
  userOrigin,
  onSuccess,
  logout,
}: {
  userOrigin: string;
  onSuccess: (response: ProfileSuccessResponse) => void;
  logout: () => void;
}): JSX.Element {
  const { t } = useTranslation('vote');
  const isMobile = useIsMobile();
  const appId = process.env.REACT_APP_FACEBOOK_KEY;

  return (
    <Flex align={'center'} w={'100%'} justify={'end'}>
      {!userOrigin.includes('facebook.com') && appId !== undefined ? (
        <FacebookLogin
          appId={appId}
          autoLoad={false}
          onProfileSuccess={onSuccess}
          render={(renderProps) => (
            <>
              <Button onClick={renderProps.onClick} variant='subtle' compact={true}>
                <Text c={'blue.7'}>{t('connectWithFacebook')}</Text>
              </Button>
              <Popover width={200} position='bottom-end' withArrow={true} shadow='md'>
                <Popover.Target>
                  <ActionIcon size={isMobile ? 'lg' : 'md'} ml={isMobile ? 5 : 0}>
                    <IconInfoCircle size={isMobile ? '1.625rem' : '1.125rem'} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Text size='sm'>{t('reasonToConnect')}</Text>
                </Popover.Dropdown>
              </Popover>
            </>
          )}
        />
      ) : (
        <Button onClick={logout} variant='subtle' compact={true}>
          <Text c={'gray.6'}>{t('disconnectFromFacebook')}</Text>
        </Button>
      )}
    </Flex>
  );
}

function handlePolisEvent(
  messageEvent: MessageEvent,
  iframeRef: React.RefObject<HTMLDivElement>,
  containerRef: React.RefObject<HTMLDivElement>,
  setPolisInit: (isOk: boolean) => void,
): void {
  if (iframeRef.current !== null) {
    if (messageEvent.data.name === 'resize') {
      iframeRef.current.style.height = `${messageEvent.data.height}px`;
      postContainerHeight(containerRef);
    } else if (messageEvent.data.name === 'init') {
      setPolisInit(messageEvent.data.status === 'ok');

      if (messageEvent.data.status !== 'ok') {
        console.error('Polis init error', messageEvent);
      }
    }
  }
}

function postContainerHeight(containerRef: React.RefObject<HTMLDivElement>): void {
  const currentContainerRef = containerRef.current;
  if (currentContainerRef != null) {
    window.parent.postMessage(
      {
        name: 'height',
        height: currentContainerRef.clientHeight,
      },
      '*',
    );
  }
}

interface CommentsCarouselStylesProps {
  color: string;
  carouselHeight: number;
}

const useCommentsCarouselStyles = createStyles(
  (theme, { color, carouselHeight }: CommentsCarouselStylesProps) => ({
    commentOverlay: {
      top: 0,
      position: 'absolute',
      zIndex: 1,

      width: 50,
      height: '100%',

      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },
    commentOverlayLeft: {
      background: theme.fn.linearGradient(90, 'white', 'rgba(255, 255, 255, 0)'),
    },
    commentOverlayRight: {
      right: 0,

      background: theme.fn.linearGradient(90, 'rgba(255, 255, 255, 0)', 'white'),
    },
    root: {
      height: carouselHeight,
      maxWidth: '100%',
      mx: 'auto',
    },
    indicator: {
      width: 12,
      height: 4,
      marginBottom: 10,

      backgroundColor: color,
      transition: 'width 250ms ease',

      '&[data-active]': {
        width: 40,
      },
    },
    indicators: {
      position: 'unset',
      paddingTop: 20,
    },
    controls: {
      display: 'none',
    },
  }),
);

function CommentsCarousel({
  color,
  maxCommentLength,
  children,
}: {
  color: string;
  maxCommentLength: number;
  children: ReactNode[];
}): JSX.Element {
  const [embla, setEmbla] = useState<Embla | null>(null);

  const carouselHeight = Math.ceil(maxCommentLength / 27) * 24 + 70;
  const { classes, cx } = useCommentsCarouselStyles({
    color,
    carouselHeight,
  });

  return (
    <Box pos={'relative'} maw={'100%'}>
      <div
        onClick={() => embla?.scrollPrev()}
        className={cx(classes.commentOverlay, classes.commentOverlayLeft)}
      />
      <Carousel
        withIndicators={true}
        align='center'
        loop={false}
        slideSize='80%'
        slideGap={10}
        classNames={classes}
        getEmblaApi={setEmbla}
      >
        {children.map((child, index) => (
          <Carousel.Slide key={index}>{child}</Carousel.Slide>
        ))}
      </Carousel>
      <div
        onClick={() => embla?.scrollNext()}
        className={cx(classes.commentOverlay, classes.commentOverlayRight)}
      />
    </Box>
  );
}

function PolisSkeleton(): JSX.Element {
  return (
    <Center mb={20} mt={{ base: 5, sm: 20 }} w={'100%'}>
      <Flex direction={'column'} w={'100%'} align={'end'}>
        <Skeleton w={{ base: '100%', sm: 700 }} height={150} />
        <Skeleton mt={30} w={{ base: '100%', sm: 700 }} height={150} />
        <Flex mt={22} w={{ base: '100%', sm: 'unset' }}>
          <Skeleton width={40} height={40} />
          <Skeleton ml={10} w={{ base: '100%', sm: 650 }} height={40} />
        </Flex>
        <Skeleton width={150} height={40} mt={30} />
      </Flex>
    </Center>
  );
}

function CommentsCarouselSkeleton(): JSX.Element {
  return (
    <Flex my={20} w={'100%'} align={'center'} direction={'column'}>
      <Flex w={'100%'} justify={'center'}>
        <Skeleton w={100} height={36} mr={48} />
        <Skeleton width={36} height={36} mr={8} />
        <Skeleton width={36} height={36} mr={8} />
        <Skeleton width={36} height={36} mr={8} />
      </Flex>
      <Skeleton w={{ base: '100%', sm: 360 }} height={120} mt={25} />
    </Flex>
  );
}
