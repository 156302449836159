import axios, { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  ClusteredConversationPoints,
  ConsensusComment,
  ConsensusCommentReportType,
  ConversationDetailType,
  ConversationParticipantType,
  ConversationReportClusterDataType,
  ConversationSourceTypeEnum,
  ConversationStatisticsType,
  ConversationType,
  ConversationVoteType,
  GroupDefiningCommentGroup,
  InternalReportSourceStats,
} from './components/types/Conversation';
import { ClusterVotePoint } from './components/types/ClusterVotePoint';

export const request = axios.create({
  baseURL: '/api',
});
const authRequest = axios.create({
  baseURL: '/api',
});
authRequest.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken && config.headers) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

authRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export function useClusteredConversationPoints<
  TError = AxiosError,
  TData = ClusteredConversationPoints[] | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  lastVoteDate: Date | null = null,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversations_points', conversationId, lastVoteDate],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}/points`);
      return data;
    },
    ...options,
  });
}

export function useClusteredConversationConsensusComments<
  TError = AxiosError,
  TData = ConsensusComment[] | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversations_consensus_comments', conversationId],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}/consensus_comments`);
      return data;
    },
    ...options,
  });
}

export function useClusteredConversationGroupDefiningComments<
  TError = AxiosError,
  TData = GroupDefiningCommentGroup[] | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversations_group_defining_comments', conversationId],
    queryFn: async () => {
      const { data } = await request.get(
        `/conversations/${conversationId}/group_defining_comments`,
      );
      return data;
    },
    ...options,
  });
}

export function useConversations<
  TError = AxiosError,
  TData = ConversationType[],
  TQueryFnData = TData,
>(
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversations'],
    queryFn: async () => {
      const { data } = await authRequest.get('/conversations');
      return data;
    },
    ...options,
  });
}

export function useConversationClusterUpdateDate<
  TError = AxiosError,
  TData = Date | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_update_date', conversationId],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}/update_date`);
      return data;
    },
    ...options,
  });
}
export function useIsConversationActive<
  TError = AxiosError,
  TData = boolean | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_is-active', conversationId],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}/is_active`);
      return data;
    },
    ...options,
  });
}

export function usePolisConversationId<
  TError = AxiosError,
  TData = string | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['polis_conversation_id', conversationId],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}/polis_id`);
      return data;
    },
    ...options,
  });
}

export function useConversationDetail<
  TError = AxiosError,
  TData = ConversationDetailType,
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_detail', conversationId],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}`);
      return data;
    },
    ...options,
  });
}

export function useConversationReportStatistics<
  TError = AxiosError,
  TData = ConversationStatisticsType,
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_statistics', conversationId],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}/report/stats`);
      return data;
    },
    ...options,
  });
}

export function useConversationPoints<
  TError = AxiosError,
  TData = ClusteredConversationPoints[],
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_points', conversationId],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}/points`);
      return data;
    },
    ...options,
  });
}

export function useConversationUserLocation<
  TError = AxiosError,
  TData = ClusterVotePoint | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  conversationUserId: string,
  lastVoteDate: Date | null = null,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_user_location', conversationId, conversationUserId, lastVoteDate],
    queryFn: async () => {
      const { data } = await request.get(`/conversations/${conversationId}/user_location`, {
        params: {
          conversation_user_id: conversationUserId,
        },
      });
      return data;
    },
    ...options,
  });
}

export function useConversationReportComments<
  TError = AxiosError,
  TData = ConsensusCommentReportType[],
  TQueryFnData = TData,
>(
  conversationId: string,
  sortBy: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_report_consensus_comments', conversationId, sortBy],
    queryFn: async () => {
      const { data } = await request.get(
        `/conversations/${conversationId}/report/comments?sort_by=${sortBy}`,
      );
      return data;
    },
    ...options,
  });
}

export function useExternalConversationEmbedCode<
  TError = AxiosError,
  TData = string | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  referralUrl: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['embed', 'code', 'external', conversationId, referralUrl],
    queryFn: async () => {
      const { data } = await request.get(
        `/embed/${conversationId}/code/external?referral_url=${referralUrl}`,
      );
      return data;
    },
    ...options,
  });
}

export function useInternalConversationEmbedCode<
  TError = AxiosError,
  TData = string | null,
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['embed', 'code', 'internal', conversationId],
    queryFn: async () => {
      const { data } = await request.get(`/embed/${conversationId}/code/internal`);
      return data;
    },
    ...options,
  });
}

export function useDeleteConversation<
  TError = AxiosError,
  TData = undefined,
  TVariables = string,
>(): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutation<TData, TError, TVariables>({
    mutationFn: async (conversationId: TVariables) => {
      return authRequest.delete(`/conversations/${conversationId}`);
    },
  });
}

export function useConversationParticipants<
  TError = AxiosError,
  TData = ConversationParticipantType[],
  TQueryFnData = TData,
>(
  conversationId: number,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_participants', conversationId],
    queryFn: async () => {
      const { data } = await authRequest.get(`/conversations/${conversationId}/participants`);
      return data;
    },
    ...options,
  });
}

export function useConversationVotes<
  TError = AxiosError,
  TData = ConversationVoteType[],
  TQueryFnData = TData,
>(
  conversationId: string,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_votes', conversationId],
    queryFn: async () => {
      const { data } = await authRequest.get(`/conversations/${conversationId}/votes`);
      return data;
    },
    ...options,
  });
}

/**
 * Request which will set conversation user id by `Set-Cookie` response header from server.
 * The conversation user ID for a specific conversation is from 2 parts. One is ID unique to the user which they share
 * through all conversations and the other is a `userOrigin` which holds the value of the origin from where the user
 * first come to the voting site.
 * Because Safari deletes cookies set by the browser (Intelligent Tracking Prevention) we need to set the user
 * conversation id by `Set-Cookie` response header from server. Call this request when conversation is displayed on
 * *.demdis.sk websites and we want to set user conversation id so the user can return later and see their votes.
 */
export function useSetConversationUserId<TError = AxiosError, TData = void, TQueryFnData = TData>(
  conversationId: string,
  userId: string | null,
  referral: string | null,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['generate_internal_conversation_user_id', conversationId, userId, referral],
    queryFn: async () => {
      return await request.get(
        `/conversations/${conversationId}/generate_internal_conversation_user_id`,
        {
          params: {
            referral: referral,
            conversation_user_id: userId,
          },
        },
      );
    },
    ...options,
  });
}

export function useConversationReportSourceStats<
  TError = AxiosError,
  TData = InternalReportSourceStats[],
  TQueryFnData = TData,
>(
  conversationId: string,
  source: ConversationSourceTypeEnum,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_internal_report_source_stats', conversationId, source],
    queryFn: async () => {
      const { data } = await authRequest.get(
        `/conversations/${conversationId}/internal_report/sources/${source}/stats`,
      );
      return data;
    },
    ...options,
  });
}

export function useConversationReportSourcesList<
  TError = AxiosError,
  TData = string[],
  TQueryFnData = TData,
>(
  conversationId: string,
  source: ConversationSourceTypeEnum,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ['conversation_internal_report_sources', conversationId, source],
    queryFn: async () => {
      const { data } = await authRequest.get(
        `/conversations/${conversationId}/internal_report/sources/${source}`,
      );
      return data;
    },
    ...options,
  });
}

export type ConversationReportClusterInputData = {
  externalSources: string[];
  internalSources: string[];
};

export function useConversationReportCustomCluster<
  TError = AxiosError,
  TData = ConversationReportClusterDataType,
>(conversationId: string): UseMutationResult<TData, TError, ConversationReportClusterInputData> {
  return useMutation<TData, TError, ConversationReportClusterInputData>({
    mutationKey: ['conversation_internal_report_cluster', conversationId],
    mutationFn: async ({ externalSources, internalSources }) => {
      const { data } = await authRequest.post(
        `/conversations/${conversationId}/internal_report/cluster`,
        { externalSources, internalSources },
      );
      return data;
    },
  });
}
