import { MantineProvider, MantineThemeOverride, rem } from '@mantine/core';
import React from 'react';
import { Notifications } from '@mantine/notifications';

export const theme: MantineThemeOverride = {
  fontFamily: 'Verdana, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  spacing: { xs: '1rem', sm: '1.2rem', md: '1.8rem', lg: '2.2rem', xl: '2.8rem' },
  colors: {
    yellow: [
      '#fffbe1',
      '#fff7cd',
      '#fded9d',
      '#fce368',
      '#fbdb3c',
      '#fad621',
      '#f2cb05',
      '#debb00',
      '#c5a600',
      '#ab8f00',
    ],
    red: [
      '#ffe4e4',
      '#ffb4b5',
      '#fa8485',
      '#f75555',
      '#f32625',
      '#da0f0c',
      '#aa0808',
      '#7b0405',
      '#4b0102',
      '#1f0000',
    ],
    cyan: [
      '#daffff',
      '#affcfc',
      '#82f9f9',
      '#55f7f7',
      '#2ef4f4',
      '#1cdbdb',
      '#08aaaa',
      '#007a7a',
      '#004a4a',
      '#001a1a',
    ],
    green: [
      '#e1ffe1',
      '#b3fcb3',
      '#84f984',
      '#55f755',
      '#28f426',
      '#13db10',
      '#08aa08',
      '#017a04',
      '#004900',
      '#001900',
    ],
  },
  fontSizes: {
    xs: rem(10),
    sm: rem(11),
    md: rem(16),
    lg: rem(18),
    xl: rem(22),
  },
  components: {
    Badge: {
      styles: {
        root: { padding: '0 calc(0.8rem / 1.5)' },
      },
    },
    Container: {
      styles: {
        root: {
          paddingTop: '0.8rem',
          paddingLeft: '0.8rem',
          paddingRight: '0.8rem',
        },
      },
    },
  },
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps): JSX.Element {
  return (
    <MantineProvider withGlobalStyles={true} withNormalizeCSS={true} theme={theme}>
      <Notifications />
      {children}
    </MantineProvider>
  );
}
