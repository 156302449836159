import React from 'react';
import { ConsensusCommentGroup } from '../../types/Conversation';
import { Group, Layer, Line, Rect, Stage, Text } from 'react-konva';
import { conversationColorFromName } from '../../../utils/conversationColor';

type Props = {
  numberOfCluster: number;
  pillarWidth: number;
  pillarPadding: number;
  graphHeight: number;
  commentStats: ConsensusCommentGroup[];
  expandGraph: boolean;
  showPercentage: boolean;
  underlineBoldness: number;
  pillarShrink: number;
};

CommentBarGraph.defaultProps = {
  underlineBoldness: 2,
  pillarShrink: 2,
};

export function CommentBarGraph({
  numberOfCluster,
  pillarWidth,
  pillarPadding,
  graphHeight,
  commentStats,
  expandGraph,
  showPercentage,
  underlineBoldness,
  pillarShrink,
}: Props): JSX.Element {
  return (
    <div>
      <Stage
        width={
          pillarPadding * 2 + numberOfCluster * pillarWidth + (numberOfCluster - 1) * pillarPadding
        }
        height={graphHeight}
      >
        <Layer listening={false}>
          <StageGrid graphHeight={graphHeight} />
          {expandGraph && (
            <>
              {Array.from(Array(15).keys()).map((x) => (
                <Group key={x}>
                  <Line
                    x={x * 7}
                    y={graphHeight / 2 + 1}
                    strokeWidth={1}
                    stroke={'#000000'}
                    points={[0, 0, 3.5, 0]}
                  />
                  <Line
                    x={x * 7}
                    y={1.3}
                    strokeWidth={1}
                    stroke={'#000000'}
                    points={[0, 0, 3.5, 0]}
                  />
                </Group>
              ))}
            </>
          )}
          {commentStats.map((stats, index) => (
            <Rect
              key={stats.name}
              x={pillarPadding + pillarPadding * index + index * pillarWidth}
              fill={conversationColorFromName(stats.name)}
              y={graphHeight}
              width={pillarWidth}
              height={-(stats.percentage / 100) * (graphHeight - pillarShrink)}
              transformsEnabled='position'
            />
          ))}
          <Rect
            x={0}
            y={graphHeight - underlineBoldness}
            height={10}
            width={100}
            fill={'black'}
            transformsEnabled='position'
          />
        </Layer>
      </Stage>

      {showPercentage && (
        <Stage
          width={pillarPadding + numberOfCluster * pillarWidth + numberOfCluster * pillarPadding}
          height={12}
        >
          <Layer listening={false}>
            {commentStats.map((stat, index) => (
              <Text
                key={stat.name}
                fontSize={8}
                x={pillarPadding + index * pillarPadding + index * pillarWidth}
                y={3}
                fill={conversationColorFromName(stat.name)}
                align={'center'}
                text={`${stat.percentage}%`}
              />
            ))}
          </Layer>
        </Stage>
      )}
    </div>
  );
}

function StageGrid({ graphHeight }: { graphHeight: number }): JSX.Element {
  return (
    <>
      {Array.from(Array(8).keys()).map((y) =>
        Array.from(Array(15).keys()).map((x) => (
          <Line
            key={`${x}${y}`}
            x={x * 7}
            y={y * (graphHeight / 6) + 1}
            strokeWidth={1}
            stroke={'#DBDBDB'}
            points={[0, 0, 3.5, 0]}
          />
        )),
      )}
    </>
  );
}
