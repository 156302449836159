import React, { Fragment, memo, useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useConversationDetail,
  useConversationPoints,
  useConversationReportComments,
} from '../../../network';
import {
  CommentInClusterType,
  ConsensusCommentReportType,
} from '../../../components/types/Conversation';
import { Layer, Line, Rect, Stage } from 'react-konva';
import {
  Accordion,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Stack,
  Switch,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import ClusterVotesPoints2D2 from '../../../components/dataVisualized/charts/ClusterVotesPoints2D2';
import round from '../../../utils/numberUtils';
import { StatementsTableSkeleton } from '../../../components/dataVisualized/commentBasedData/StatementsTable';
import { keepPreviousData } from '@tanstack/react-query';
import { getConsensusFromClusterComments } from '../../../utils/comments';

interface ConversationReportRouterProps extends Params {
  conversationId: string;
}

const REQUEST_PERIOD = 5000;

export function ConversationPresentation(): JSX.Element {
  const { conversationId } = useParams<ConversationReportRouterProps>();

  if (conversationId === undefined) {
    return <div>Invalid conversation ID</div>;
  }

  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);

  const { data: pointsData } = useConversationPoints(conversationId, {
    refetchInterval: autoRefresh ? REQUEST_PERIOD : false,
  });
  const { data: detail } = useConversationDetail(conversationId);
  const { data: comments, isLoading: isCommentsLoading } = useConversationReportComments(
    conversationId,
    'consensus',
    {
      refetchInterval: autoRefresh ? REQUEST_PERIOD : false,
      placeholderData: keepPreviousData,
    },
  );

  return (
    <Grid gutter={0} m={0} columns={3}>
      <Grid.Col span={1}>
        {isCommentsLoading && <StatementsTableSkeleton />}
        {comments && (
          <div style={{ overflowY: 'scroll', maxHeight: '100vh' }}>
            <Accordion>
              {comments.map((comment, index) => (
                <Fragment key={index}>
                  <ConsensusCommentReport key={index} comment={comment} />
                  {index < comments.length - 1 && <Divider />}
                </Fragment>
              ))}
            </Accordion>
          </div>
        )}
      </Grid.Col>
      <Grid.Col span={2}>
        <Flex justify={'space-between'} mih={50} align={'center'}>
          {detail && <Title order={1}>{detail.name}</Title>}
          <Switch
            checked={autoRefresh}
            onChange={(event) => {
              setAutoRefresh(event.currentTarget.checked);
            }}
            label={'Auto-Refresh'}
          />
        </Flex>
        <Center m={'auto'}>
          {pointsData && (
            <ClusterVotesPoints2D2
              points={pointsData}
              votingUserDatapoint={null}
              maxWidth={'100%'}
            />
          )}
        </Center>
      </Grid.Col>
    </Grid>
  );
}

const ConsensusCommentReport = memo(function ConsensusCommentReport({
  comment,
}: {
  comment: ConsensusCommentReportType;
}): JSX.Element {
  const consensusCommentInCluster = getConsensusFromClusterComments(comment);

  return (
    <Accordion.Item value={comment.id.toString()}>
      <Accordion.Control>
        <div style={{ fontSize: 20, display: 'flex', alignItems: 'baseline' }}>
          <p style={{ marginLeft: '5px', marginRight: '10px', minWidth: '30px', maxWidth: '30px' }}>
            {comment.id}
          </p>
          <p style={{ marginRight: '10px', minWidth: '400px', maxWidth: '80%' }}>{comment.text}</p>
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        <div style={{ display: 'flex', gap: '20px' }}>
          <CommentInCluster comment={consensusCommentInCluster} />
          {comment.groups.map((group, index) => (
            <CommentInCluster key={index} comment={group} />
          ))}
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  );
});

const CommentInCluster = memo(function CommentInCluster({
  comment,
}: {
  comment: CommentInClusterType;
}): JSX.Element {
  const { t } = useTranslation('presentation');
  const totalVotes =
    comment.agreeCount + comment.disagreeCount + comment.skippedCount + comment.unseenCount;

  const barWidth = 150;
  const barHeight = 20;
  const borderWidth = 1;
  const barInnerWidth = barWidth - borderWidth * 2;

  const agreeWidth = (comment.agreeCount / totalVotes) * barInnerWidth;
  const disagreeWidth = (comment.disagreeCount / totalVotes) * barInnerWidth;
  const skippedWidth = (comment.skippedCount / totalVotes) * barInnerWidth;

  return (
    <Tooltip
      label={`Agree: ${comment.agreeCount} | Disagree: ${comment.disagreeCount} | Skipped: ${comment.skippedCount} | Unseen: ${comment.unseenCount}`}
    >
      <Flex direction={'column'}>
        {comment.clusterName === '' ? (
          <div> {t('consensus')}</div>
        ) : (
          <div>{comment.clusterName}</div>
        )}
        <Stage width={barWidth} height={barHeight}>
          <Layer listening={false}>
            <Line
              points={[0, 0, barWidth, 0, barWidth, barHeight, 0, barHeight, 0, 0]}
              stroke={'#000000'}
              strokeWidth={borderWidth}
            />
            <Rect
              x={borderWidth}
              y={borderWidth}
              width={agreeWidth}
              height={barHeight - borderWidth * 2}
              fill={'#65c97a'}
              transformsEnabled='position'
            />
            <Rect
              x={agreeWidth + borderWidth}
              y={borderWidth}
              width={disagreeWidth}
              height={barHeight - borderWidth * 2}
              fill={'#d65745'}
              transformsEnabled='position'
            />
            <Rect
              x={agreeWidth + disagreeWidth + borderWidth}
              y={borderWidth}
              width={skippedWidth}
              height={barHeight - borderWidth * 2}
              fill={'#e6e6e6'}
              transformsEnabled='position'
            />
          </Layer>
        </Stage>
        <Group spacing={9} align={'end'}>
          <Stack spacing={3}>
            <Text fz={'md'} span={true} c={'green'}>
              {round((comment.agreeCount / totalVotes) * 100)}%
            </Text>
            <Text fz={'md'} span={true} c={'green'}>
              {comment.agreeCount}
            </Text>
          </Stack>
          <Stack spacing={3}>
            <Text fz={'md'} span={true} c={'red'}>
              {round((comment.disagreeCount / totalVotes) * 100)}%
            </Text>
            <Text fz={'md'} span={true} c={'red'}>
              {comment.disagreeCount}
            </Text>
          </Stack>
          <Stack spacing={3}>
            <Text fz={'md'} span={true} c={'gray'}>
              {round((comment.skippedCount / totalVotes) * 100)}%
            </Text>
            <Text fz={'md'} span={true} c={'gray'}>
              {comment.skippedCount}
            </Text>
          </Stack>
          <Text fz={'md'} span={true} c={'gray'}>
            ({comment.skippedCount + comment.disagreeCount + comment.agreeCount})
          </Text>
        </Group>
      </Flex>
    </Tooltip>
  );
});
