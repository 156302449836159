import React from 'react';
import { ErrorFallback } from './utils/errorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { initTranslations } from './i18n/i18n';
import { CookiesProvider } from 'react-cookie';
import { Router } from './routes/Router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from './ThemeProvider';

const queryClient = new QueryClient();

export function App(): JSX.Element {
  initTranslations();
  return (
    <React.StrictMode>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Router />
            </ErrorBoundary>
          </ThemeProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </React.StrictMode>
  );
}
