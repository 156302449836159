import React, { useState } from 'react';
import { Params, useParams, useSearchParams } from 'react-router-dom';

import {
  useConversationDetail,
  useConversationPoints,
  useConversationReportComments,
  useConversationReportStatistics,
} from '../../../network';
import Header from '../../../components/Header';
import { Container, Title } from '@mantine/core';
import ClusterVotesPoints2D2 from '../../../components/dataVisualized/charts/ClusterVotesPoints2D2';
import {
  StatementsTable,
  StatementsTableSkeleton,
} from '../../../components/dataVisualized/commentBasedData/StatementsTable';
import ConversationStatistics from './common';
import { keepPreviousData } from '@tanstack/react-query';

interface ExternalConversationReportRouterProps extends Params {
  conversationId: string;
}

export function ExternalConversationReport(): JSX.Element {
  const { conversationId } = useParams<ExternalConversationReportRouterProps>();

  if (conversationId === undefined) {
    return <div>Invalid conversation ID</div>;
  }

  const [searchParams] = useSearchParams();
  const commentIdToHighlight = searchParams.get('comment_id');
  const [sortBy, setSortBy] = useState('consensus');

  const { data: pointsData } = useConversationPoints(conversationId);
  const { data: detail } = useConversationDetail(conversationId);
  const { data: statistics } = useConversationReportStatistics(conversationId);
  const { data: comments } = useConversationReportComments(conversationId, sortBy, {
    placeholderData: keepPreviousData,
  });

  return (
    <div>
      <Header />
      <Container fluid={true}>
        {detail && (
          <>
            <Title order={1}>{detail.name}</Title>
          </>
        )}
        <ConversationStatistics statistics={statistics} conversationName={detail?.name || ''} />
        {pointsData && (
          <div style={{ marginTop: '20px' }}>
            <ClusterVotesPoints2D2 points={pointsData} votingUserDatapoint={null} />
          </div>
        )}
        {comments ? (
          <StatementsTable
            comments={comments}
            commentIdToHighlight={commentIdToHighlight}
            showCopyButton={true}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        ) : (
          <StatementsTableSkeleton />
        )}
      </Container>
    </div>
  );
}
