import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en, sk } from './locales';

const DEFAULT_LANGUAGE: SupportedLanguage = 'sk';

type Resources = {
  [key in SupportedLanguage]: Translations;
};
type Translations = typeof sk;
type SupportedLanguage = 'en' | 'sk';

const resources: Resources = {
  en,
  sk,
};

export function initTranslations(): void {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: process.env.NODE_ENV === 'development',
      fallbackLng: DEFAULT_LANGUAGE,
      interpolation: {
        escapeValue: false,
        format: (value, format) => {
          if (format === 'uppercase') {
            return value.toUpperCase();
          }
          if (format === 'capitalize') {
            return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;
          }
          return value;
        },
      },
      resources,
    });
}
