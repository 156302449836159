import React from 'react';
import { Params, useParams } from 'react-router-dom';

interface ConversationVotingVoteRouteParams extends Params {
  conversationId: string;
}

interface ConversationVotingProps {
  conversation: (conversationId: string) => JSX.Element;
}

export default function ConversationVoting(props: ConversationVotingProps): JSX.Element {
  const { conversationId } = useParams() as ConversationVotingVoteRouteParams;

  if (conversationId === undefined) {
    return <h1 style={{ textAlign: 'center' }}>Nesprávne ID konverzácie</h1>;
  }

  return props.conversation(conversationId);
}
