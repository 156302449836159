import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps): JSX.Element {
  const { t } = useTranslation('errors');
  return (
    <div role='alert'>
      <p>{t('errors:error.unexpected')}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{t('errors:error.tryAgain')}</button>
    </div>
  );
}
